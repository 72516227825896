export const languagesCodes: { [name: string]: string } = {
    Arabic: 'ar',
    English: 'en',
    Spanish: 'es',
    German: 'de',
    French: 'fr',
    Japanese: 'ja',
    Korean: 'ko',
    Portuguese: 'pt',
    Russian: 'ru',
    Turkish: 'tr',
    Vietnamese: 'vi',
    Italian: 'it',
    Mandarin: 'zh_CN'
};
export const languagesArray = Object.keys(languagesCodes);
