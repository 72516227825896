import UploadSuccessImg from 'assets/icons/upload-success.svg';
import { FlexGrow, Row } from 'components/wrappers/FlexWrapper';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { modalStores } from 'stores/initialize/initialize.modal.store';
import { Sizes } from 'types/styles';
import { ModalTransparentWrapper } from '../ModalWrapper';
import { defaultTitle } from '../SuccessModal/constants';
import { TitleSpan, TitleWrapper, TitleWrapperProps } from '../SuccessModal/styles';
import { ProgressModalBody, ProgressModalLine, ProgressModalPoster, UploadProgressIcon } from './styles';

interface UploadProgressModalProps extends TitleWrapperProps, Sizes {}

const { uploadProgressModal } = modalStores;

export const UploadProgressModal: FC<UploadProgressModalProps> = () => {
    const { visible, uploaded, progress, poster } = useStore(uploadProgressModal);

    const states = {
        uploading: 'Video is uploading...',
        uploaded: 'Video uploaded!'
    };

    return (
        <ModalTransparentWrapper visible={visible}>
            <ProgressModalBody>
                <ProgressModalPoster src={poster} />
                <FlexGrow alignCenter flexDirection="column" flexGrow="1" marginLeft="14px">
                    <TitleWrapper>
                        {uploaded ? (
                            <Row>
                                <UploadProgressIcon src={UploadSuccessImg} />
                                <TitleSpan>{states.uploaded}</TitleSpan>
                            </Row>
                        ) : (
                            <TitleSpan>{defaultTitle}</TitleSpan>
                        )}
                    </TitleWrapper>
                    <ProgressModalLine progress={progress || 0} />
                </FlexGrow>
            </ProgressModalBody>
        </ModalTransparentWrapper>
    );
};
