import { InputProps } from 'components/common/Input';
import { inputLineHeight, inputTextHorizontalPadding } from 'components/common/Input/StyledInput/constants';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import { IsError } from 'types/forms';

interface InputStyleMixinProps extends Pick<InputProps, 'disabled' | 'type'>, IsError {}

export const inputStyleMixin = css<InputStyleMixinProps>`
    ${disableDefaultButtonStyleMixin};
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    ${({ type }) => type === 'password' && 'letter-spacing: 2px;'};
    line-height: ${inputLineHeight};
    padding: ${inputTextHorizontalPadding};
    padding-right: 30px;
    box-sizing: border-box;
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            /* font-size: 18px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: left; */
        `}

    width: 100%;
    &::placeholder {
        color: rgba(#3c3c3c, 0.5);
        font-weight: 400;
        ${({ type }) => type === 'password' && 'letter-spacing: 0px;'};
    }
    &:-ms-input-placeholder {
        color: rgba(#3c3c3c, 0.5);
        font-weight: 400;
    }
    // Making autofill background in Chrome white
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset;
    }
`;

export const StyledInput = styled.input`
    ${inputStyleMixin};
`;

export const FilledStyledInput = styled.input`
    ${disableDefaultButtonStyleMixin};
    height: 60px;
    width: 100%;
    border-radius: 10px;
    background: #f4f4f4;
    color: #000;
    font-weight: 700;
    position: relative;
    outline: none;
    margin-top: 15px;
    margin-bottom: 25px;
    padding-left: 20px;

    &::placeholder {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        color: black;
    }
`;
