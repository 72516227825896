import styled from 'styled-components';
import backgroundImg from 'assets/WelcomePageBackground.svg';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { welcomeBackgroundColor } from './constants';

export const WelcomeWrapper = styled.div`
    height: 100%;
    width: 100%;
    background-image: linear-gradient(${welcomeBackgroundColor}, ${welcomeBackgroundColor}), url(${backgroundImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

// export const BlackBackground = styled(Column)`
//     height: 100%;
//     width: 100%;
//     background-color: rgba(0, 0, 0, 0.5);
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
// `;

export const SpanWrapper = styled(MarginWrapper)`
    text-align: center;
    max-width: 340px;
`;
