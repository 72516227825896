import { CodeInput } from 'components/common/Input/CodeInput';
import { useField } from 'formik';
import React from 'react';

interface CodeFieldProps {
    name: string;
}

export const CodeField = ({ name }: CodeFieldProps) => {
    const [field, { touched, error }] = useField(name);

    return (
        <CodeInput {...field} codeLength={6} error={error} touched={touched} /*isError={!!error} isValid={isValid}*/ />
    );
};
