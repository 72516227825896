import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { Steps, stepsEvents } from 'stores/steps';
import { createNotifyingEffect } from 'utils/common';

//*** events *****

const setOrganizationPublicId = createEvent<string>('');
const setFacilitatorId = createEvent<string>('');
const setInitialRemoteProductValues = createEvent<WOM.RemoteProductDescription>();
const setRemoteProductValues = createEvent<WOM.RemoteProductDescription>();
const setErrors = createEvent<boolean>();

//*** effects *****

const queryListOfProducts = createNotifyingEffect({
    handler: async (name: string) =>
        await API.product.queryListOfProducts({
            name,
            limit: 5,
            returnQueryCount: true
        })
});

const createProduct = createNotifyingEffect({
    handler: async (name: string) =>
        await API.product.createProduct({
            name
            // upc: ''
        })
});

//*** stores *****

const organizationPublicId = createStore<string>('').on(setOrganizationPublicId, (_, state) => state);
const facilitatorId = createStore<string>('').on(setFacilitatorId, (_, state) => state);

const initialRemoteProductValues = createStore<WOM.RemoteProductDescription>({}).on(
    setInitialRemoteProductValues,
    (_, { ...newValues }) => newValues
);
//initialRemoteProductValues.watch(state => console.log('initialRemoteProductValues state', state));

const remoteProductValues = createStore<WOM.RemoteProductDescription>({}).on(
    setRemoteProductValues,
    (state, { ...newValues }) => ({ ...state, ...newValues })
);
//remoteProductValues.watch(state => console.log('remoteProductValues state', state));

const initializeRemoteProduct = createEffect({
    handler: async () => {
        try {
            const Id = organizationPublicId.getState();
            const remoteProduct = remoteProductValues.getState();

            const response = await API.womServices.product.initializeRemoteProduct({
                organizationPublicId: Id,
                remoteProduct
            });

            return response;
        } catch (error) {
            let message = error.message;

            if (!message || message === 'unknown') {
                message = 'Some error occurred. Please try again.';
            }
            modalEvents.openAsyncModal({
                title: 'Error',
                errorText: message,
                onOk: () => stepsEvents.setStep(Steps.Welcome)
            });

            setErrors(true);
            return {};
        }
    }
});

const productId = createStore('').on(initializeRemoteProduct.doneData, (_, { productId }) => productId);
//productId.watch(state => console.log('productId state', state));

const productErrors = createStore(false).on(setErrors, (_, newState) => newState);
//productErrors.watch(state => console.log('productErrors state', state));

//*** exports *****
export const productEvents = {
    setInitialRemoteProductValues,
    setRemoteProductValues,
    setOrganizationPublicId,
    setFacilitatorId
};
export const productEffects = {
    queryListOfProducts,
    initializeRemoteProduct,
    createProduct
};
export const productStores = {
    initialRemoteProductValues,
    remoteProductValues,
    organizationPublicId,
    facilitatorId,
    productId,
    productErrors
};
