import yeayLogo from 'assets/logo.png';
import { NavigationButton } from 'components/common/buttons/NavigationButton';
import { RelativeWrapperTopBar, StickyPositionWrapper, TopBarTitle } from 'components/common/TopBar/styles';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import React, { FC } from 'react';
import { CustomImg } from '../CustomImg';

interface Props {
    onButtonClick: () => void;
    title?: string;
}

export const TopBar: FC<Props> = ({ onButtonClick, title }) => (
    <StickyPositionWrapper>
        <RelativeWrapperTopBar>
            <AbsoluteWrapper bottom="10px" left="25px">
                <NavigationButton type="back" onClick={onButtonClick} />
            </AbsoluteWrapper>
            <CustomImg height="auto" src={yeayLogo} width="82px" />
            {title && <TopBarTitle>{title}</TopBarTitle>}
        </RelativeWrapperTopBar>
    </StickyPositionWrapper>
);
