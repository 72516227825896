// * common
export const green = '#00ff44';
export const black = '#000000';
export const white = '#ffffff';
export const orange = '#E79B61';
export const dark = '#3c3c3c';
export const grey = '#E3E3E3';
export const grey2 = '#d8d8d8';
export const grey3 = '#767a9f';
export const grey4 = '#9A9DB4';
export const grey5 = '#E1E2E9';
export const grey6 = '#f4f4f4';
export const grey7 = '#b3b3b3';
export const grey8 = '#f7f7f7';
export const grey9 = '#b0b0b0';
export const grey10 = '#eeeeee';

// * special
export const yeayColor = '#86f796';

export const errorColor = '#fb003f';
