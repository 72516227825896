import { Section } from 'components/wrappers/FlexWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { white } from 'constants/styles/colors';
import styled from 'styled-components';

export const RelativeWrapperTopBar = styled(RelativeWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TopBarTitle = styled.h2`
    font-size: 16px;
`;

export const StickyPositionWrapper = styled(Section)`
    position: sticky;
    top: 0px;
    padding-top: 55px;
    background-color: ${white};
    z-index: 1;
`;
