//import closeRecordIcon from 'assets/icons/record_close.svg';
import closeRecordIcon from 'assets/icons/cross-icon.svg';
import startRecordIcon from 'assets/icons/start-record.svg';
import stopRecordIcon from 'assets/icons/stop-record.svg';
import switchCameraIcon from 'assets/icons/switch-camera1.svg';
import timerIcon from 'assets/icons/timer.svg';
import {
    closeRecordButtonDiameter,
    playButtonDiameter,
    playButtonTextLineHeight,
    switchCameraButtonDiameter,
    timerButtonDiameter
} from 'components/common/VideoRecord/constants';
import { grey } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import { enableMicroButtonPrimaryPadding } from 'constants/styles/sizes';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { Sizes } from 'types/styles';

export const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: hidden;

    .video-js {
        width: 100%;
        height: 100%;

        video {
            object-position: top;
        }
    }

    .vjs-record-indicator {
        display: none;
    }
`;

export const TimerButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: ${timerButtonDiameter};
    height: ${timerButtonDiameter};
    border-radius: 50%;
    background-color: white;
    background-image: url(${timerIcon});
`;

export const CloseRecordButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    width: ${closeRecordButtonDiameter};
    height: ${closeRecordButtonDiameter};
    border-radius: 50%;
    background-image: url(${closeRecordIcon});
    background-size: cover;
`;

interface PlayButtonProps {
    buttonType: 'start' | 'stop';
}

export const PlayButton = styled.button<PlayButtonProps>`
    ${disableDefaultButtonStyleMixin};
    /* position: absolute;
    top: 50%;
    left: 50%; */
    width: ${playButtonDiameter};
    height: ${playButtonDiameter};
    // TODO: make it as just an icons
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url(${({ buttonType }) => (buttonType === 'start' ? startRecordIcon : stopRecordIcon)});
    background-position: ${({ buttonType }) => (buttonType === 'start' ? '56%' : '50%')};
    background-repeat: no-repeat;
    background-size: ${({ buttonType }) => (buttonType === 'start' ? '34px 28px' : '30px')};
    margin: 10px 0;
    /* transform: translate(-50%, -50%); */
`;

export const PlayButtonText = styled.span`
    height: ${playButtonTextLineHeight};
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: ${playButtonTextLineHeight};
    letter-spacing: 0px;
    text-align: center;
    color: white;
`;

export const AlignmentPlug = styled.div<Sizes>`
    width: ${({ width }) => width || timerButtonDiameter};
    height: ${({ height }) => height || timerButtonDiameter};
    border-radius: 50%;
`;

export const SwitchCameraButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    width: ${switchCameraButtonDiameter};
    height: ${switchCameraButtonDiameter};
    border-radius: 50%;
    /* background-color: white; */
    background-image: url(${switchCameraIcon});
    background-size: cover;
`;

export const VideoJSStyles = css`
    //video {
    //    object-fit: cover !important;
    //}

    .video-js {
        background-color: black !important;
    }

    .vjs-record.vjs-device-button.vjs-control.vjs-icon-av-perm {
        left: ${enableMicroButtonPrimaryPadding};
        width: calc(100% - 2 * ${enableMicroButtonPrimaryPadding});
        height: 48px;
        border-radius: 24px;
        background-color: ${grey};
        text-align: center;
        margin: 0;
        padding: 0 ${enableMicroButtonPrimaryPadding};

        & > span {
            display: none;
        }

        &:before {
            display: inline-block;
            height: 48px;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0.5px;
            text-align: center;
            content: 'Enable camera and microphone';
            color: black;
        }
    }
`;
