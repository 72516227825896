import bgImage from 'assets/background.svg';
import styled from 'styled-components';
import { entryBackgroundColor } from './constants';

interface Props {
    background?: string;
}

export const PageWrapper = styled.section<Props>`
    background-image: linear-gradient(${entryBackgroundColor}, ${entryBackgroundColor}),
        url(${({ background }) => background || bgImage});
    background-position: center;
    background-size: cover;
    min-height: 100%;
    padding: 85px 56px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
`;

export const Logo = styled.img`
    margin-bottom: 20px;
    width: 220px;
`;

export const Title = styled.h1`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 1px;
    text-align: center;
    color: white !important;
`;

// export const GoButton = styled(Button)`
//     width: 240px;
// `;
//
// export const StyledButton = styled.button<HomeButtonProps>`
//     ${disableDefaultButtonStyleMixin};
//     min-width: 250px;
//     height: 57px;
//     border-radius: 32px;
//     background-color: ${({ backgroundColor }) => backgroundColor || '#E79B61'};
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 700;
//     //line-height: 22px;
//     letter-spacing: 1px;
//     text-align: center;
//     color: ${({ color }) => color || 'white'};
// `;
