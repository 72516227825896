import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { productEffects } from 'stores/product';
import { Steps, stepsEvents } from 'stores/steps';
import { createNotifyingEffect } from 'utils/common';

//
/*** Local store ***/
//
const setRecorderVideo = createEvent<Blob | null>();
const setRecorderVideoScreen = createEvent<string>();
const setRecorderVideoDuration = createEvent<number>();
const setRecorderVideoDate = createEvent<string>();
const setErrors = createEvent<boolean>();

const recorderVideo = createStore<Blob | null>(null).on(setRecorderVideo, (_, video) => video);
const recorderVideoScreen = createStore<string>('').on(setRecorderVideoScreen, (_, screen) => screen);
const recorderVideoDuration = createStore<number>(0).on(setRecorderVideoDuration, (_, duration) => duration);
const recorderVideoDate = createStore<string>('').on(setRecorderVideoDate, (_, date) => date);

const updateNewVideoRequestValues = createEvent<YEAY.CreateVideoRequest>();
const newVideoRequestValues = createStore<YEAY.CreateVideoRequest>({})
    .on(updateNewVideoRequestValues, (state, newState) => ({
        ...state,
        ...newState
    }))
    .on(productEffects.initializeRemoteProduct.doneData, (state, { productId }) => ({ ...state, productId }));

//newVideoRequestValues.watch(state => console.log('newVideoRequestValues state', state));

const invokeNewVideoRequest = createEffect({
    handler: async () => {
        try {
            const videoParameters = newVideoRequestValues.getState();
            const response = await API.video.newRequest(videoParameters);

            return response;
        } catch (error) {
            let message = error.message;

            if (!message || message === 'unknown') {
                message = 'Some error occurred. Please try again.';
            }
            modalEvents.openAsyncModal({
                title: 'Error',
                errorText: message,
                onOk: () => stepsEvents.setStep(Steps.Welcome)
            });

            setErrors(true);
            return {};
        }
    }
});

const newVideoResponse = createStore<YEAY.CreateVideoResponse>({}).on(invokeNewVideoRequest, (_, newState) => newState);
//newVideoResponse.watch(state => console.log('video.newRequest state', state));

const videoErrors = createStore(false).on(setErrors, (_, newState) => newState);
//videoErrors.watch(state => console.log('videoErrors state', state));

//
/*** API effects ***/

// const uploadVideo = createNotifyingEffect({
//     handler: async (data: { videoId: string; videoStream: Blob }) =>
//         await API.media.upload(data.videoId, data.videoStream)
// });

const validateContent = createNotifyingEffect({
    handler: async (data: YEAY.WOMStartValidationRequest) => await API.wom.beginValidation(data)
});

//
/*** Return ***/
//
const videoEvents = {
    updateNewVideoRequestValues
};
const videoEffects = {
    setRecorderVideo,
    setRecorderVideoScreen,
    setRecorderVideoDuration,
    setRecorderVideoDate,
    // uploadVideo,
    validateContent,
    invokeNewVideoRequest
};

const videoStores = {
    recorderVideo,
    recorderVideoScreen,
    recorderVideoDuration,
    recorderVideoDate,
    newVideoRequestValues,
    newVideoResponse,
    videoErrors
};

export { videoEvents, videoStores, videoEffects };
