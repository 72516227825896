// error form
export const requiredFieldMessage = 'This field is required';

export const inValidUsernameMessage = 'Looks like that username is invalid';
export const invalidPhoneNumberFormatMessage = 'Hey, sorry, but this does not looks like a valid phone number 😔';
export const invalidUrlFormatMessage = 'Hey, sorry, but this does not looks like a valid url 😔';
export const inValidEmailMessage = 'Not quite a valid address... 😁';
export const disposableEmailMessage = 'Hey, sorry, but we cannot use single use email addresses 😔';
export const invalidDateOfBirthMessage = 'You should be at least 13 years old.';
export const insufficientUsernameMessage = 'Oops, your username is too short 😢';

export const notFoundPhoneNumberMessage = 'Hey, sorry, but this phone number is not found in the system 😔';

export const usernameAlreadyTakenMessage = 'Oops, your username is taken 😢';
export const phoneNumberAlreadyExistsMessage =
    'Oh WOW! We already know you! You should login instead, or change your phone...';
export const emailAlreadyExistsMessage = 'Oh WOW! We already know you! You should login instead, or change your email.';

export const codeLengthMessage = 'Codes will be 6 digits long';
