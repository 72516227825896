import React, { useEffect, useState } from 'react';
import { SimpleButton } from 'components/common/buttons/SimpleButton';
import { Row } from 'components/wrappers/FlexWrapper';
import { NoopClick } from 'types';
import { Span } from 'components/common/typography/Span';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { useTimeout } from 'hooks/use.timeout';
import { useInterval } from 'hooks/use.interval';

export interface ResetTimerProps {
    children: string;
    /* timer in sec */
    timer?: number;
    onAction: NoopClick;
}

export const ResetTimer = ({ children, timer = 10, onAction }: ResetTimerProps) => {
    const [timersTimeout, setTimersTimeout] = useState<null | number>(null);
    const [timersInterval, setTimersInterval] = useState<null | number>(null);
    const [countdown, setCountdown] = useState(timer);

    useTimeout(() => {
        setCountdown(timer);
        setTimersTimeout(null);
        setTimersInterval(null);
    }, timersTimeout);

    useInterval(() => setCountdown(timer => timer - 1), timersInterval);

    const initialTimers = () => {
        setTimersTimeout(timer * 1000);
        setTimersInterval(1000);
    };

    const onClick = () => {
        onAction();
        initialTimers();
    };

    useEffect(() => {
        initialTimers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <SimpleButton disabled={!!timersTimeout} onClick={onClick}>
                {children}
            </SimpleButton>
            {!!timersTimeout && (
                <MarginWrapper marginLeft="13px">
                    <Span fontSize="13px" fontStyle="normal" fontWeight="600" lineHeight="20px">
                        {countdown} sec
                    </Span>
                </MarginWrapper>
            )}
        </Row>
    );
};
