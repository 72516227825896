import checkmarkIcon from 'assets/icons/checkbox-check-mark.svg';
import { CustomImg } from 'components/common/CustomImg';
import { checkMarkIconHeight, checkMarkIconWidth } from 'components/common/Input/Checkbox/constants';
import { HiddenCheckbox, Label, VisibleCheckbox, Wrapper } from 'components/common/Input/Checkbox/styles';
import { CheckboxProps } from 'components/common/Input/Checkbox/types';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { noop } from 'constants/functions';
import React, { useEffect, useState } from 'react';

interface Props extends CheckboxProps {
    onChange?: (checked: boolean) => void;
    name: string;
    backgroundColor?: string;
}

export const BooleanCheckbox = ({
    defaultChecked = false,
    disabled = false,
    onChange = noop,
    name,
    backgroundColor
}: Props) => {
    const [checked, setChecked] = useState(defaultChecked);

    const onCheckboxChange = () => {
        setChecked(!checked);
        onChange(!checked);
    };

    useEffect(() => setChecked(defaultChecked), [defaultChecked]);

    return (
        <Wrapper>
            <Label>
                <VisibleCheckbox backgroundColor={backgroundColor} checked={checked} disabled={disabled}>
                    <AbsoluteWrapper bottom="6px" left="4px">
                        <CustomImg
                            alt="Checkbox"
                            height={checkMarkIconHeight}
                            src={checkmarkIcon}
                            width={checkMarkIconWidth}
                        />
                    </AbsoluteWrapper>
                </VisibleCheckbox>
                <HiddenCheckbox checked={checked} disabled={disabled} name={name} onChange={onCheckboxChange} />
            </Label>
        </Wrapper>
    );
};
