import { InputAccountType, LoginInputType } from 'components/common/grid/InputSelector/constants';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import React from 'react';
import { Selector, SelectorItem } from './styles';

export interface InputSelectorProps {
    activeItem?: string;
    onChange: (item: LoginInputType) => void;
    inputs: [InputAccountType, InputAccountType];
}

export const InputSelector = ({ inputs, activeItem = inputs[0].title, onChange }: InputSelectorProps) => (
    <Column width="100%">
        <Section marginBottom="40px">
            <Selector>
                {inputs.map(({ title, type }) => (
                    <SelectorItem key={title} isActive={activeItem === type} onClick={() => onChange(type)}>
                        {title}
                    </SelectorItem>
                ))}
            </Selector>
        </Section>
        <Section>{inputs.filter(({ type }) => type === activeItem).map(({ input }) => input)}</Section>
    </Column>
);
