import { SafariFallbackStyled } from 'pages/VideoRecord/styles';
import React from 'react';
import { Button } from 'components/common/buttons/Button';
import { iOS } from 'utils/mobile';

/**
 * Please got to Develop -> Experimanl Feature -> Media Recorder (enable)
 */
export const SafariFallback = () => (
    <SafariFallbackStyled>
        <p style={{ marginBottom: '20px' }}>
            Safari needs to enable video recording
            <br />
            {iOS
                ? 'Please got to Settings -> Safari -> Advanced -> Experimental Feature -> Media Recorder (enable)'
                : 'Please got to Develop -> Experimental Feature -> Media Recorder (enable)'}
        </p>
        <Button width="200px" onClick={() => window.location.reload()}>
            Reload all page
        </Button>
    </SafariFallbackStyled>
);
