import errorIcon from 'assets/icons/error_icon.svg';
import yeayValidIcon from 'assets/icons/valid_icon.svg';
import { Loader } from 'components/common/blocks/Loader';
import { CustomImg } from 'components/common/CustomImg';
import { InputProps } from 'components/common/Input/index';
import { errorIconDiameter, loaderDiameter, validIconDiameter } from 'components/common/Input/StyledInput/constants';
import { Column } from 'components/wrappers/FlexWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { useToggle } from 'hooks';
import React, { useEffect, useState } from 'react';
import { IsError } from 'types/forms';
import { InformationInputWrapper, InputInformationSpan, Label, ShowPasswordButton, StyledInputWrapper } from './styles';

export interface StyledInputProps extends InputProps, IsError {
    error?: string;
    validText?: string;
    isLoading?: boolean;
    touched: boolean;
    value?: string;
    hideError?: boolean;
    label?: string;
    // isValid?: boolean;
}

export const StyledInput = ({
    label,
    error,
    touched,
    hideError = false,
    validText,
    isLoading,
    // isValid,
    type,
    ...inputProps
}: StyledInputProps) => {
    const [inputType, setInputType] = useState(type);
    const [isShowPassword, setShowPassword] = useToggle();
    const isPasswordType = type === 'password';

    const onChangePasswordView = () => {
        setInputType(isShowPassword ? 'password' : 'text');
        setShowPassword();
    };

    useEffect(() => setInputType(type), [type]);

    return (
        <Column width="100%">
            {!!label && <Label>{label}</Label>}
            <RelativeWrapper>
                <StyledInputWrapper
                    className="border_color"
                    error={error}
                    touched={touched}
                    type={inputType}
                    {...inputProps}
                />
                {!isPasswordType && (
                    <>
                        {isLoading && (
                            <InformationInputWrapper>
                                <Loader diameter={loaderDiameter} />
                            </InformationInputWrapper>
                        )}
                        {touched && !!error && !isLoading && (
                            <InformationInputWrapper>
                                <CustomImg src={errorIcon} width={errorIconDiameter} />
                            </InformationInputWrapper>
                        )}
                        {!!inputProps.value && !isLoading && !error && (
                            <InformationInputWrapper>
                                <CustomImg src={yeayValidIcon} width={validIconDiameter} />
                            </InformationInputWrapper>
                        )}
                    </>
                )}
                {isPasswordType && (
                    <InformationInputWrapper onClick={onChangePasswordView}>
                        <ShowPasswordButton type="button">{isShowPassword ? 'Hide' : 'Show'}</ShowPasswordButton>
                    </InformationInputWrapper>
                )}
            </RelativeWrapper>

            {!hideError && (
                <InputInformationSpan error={error} touched={touched}>
                    {touched && error ? error : validText}
                </InputInformationSpan>
            )}
        </Column>
    );
};
