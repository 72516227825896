import { messageMargin } from 'components/modals/AsyncModal/constants';
import { Section } from 'components/wrappers/FlexWrapper';
import { white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

interface ButtonsWrapperProps {
    withCloseButton?: boolean;
}
export const ButtonsWrapper = styled(Section)<ButtonsWrapperProps>`
    align-items: center;
    justify-content: ${({ withCloseButton }) => (withCloseButton ? 'space-between' : 'center')};
`;

export const Modal = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 290px;
    padding: 30px 25px;
    border-radius: 20px;
    background-color: ${white};
`;

export const TextWrapper = styled(Section)`
    ${flexCenter};
    margin: ${messageMargin};
    padding: 10px 5px;
    max-height: 250px;
    overflow: auto;
`;
