import styled from 'styled-components';

export const StyledModalWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
`;

export const StyledModalTransparentWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
`;
