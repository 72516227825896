import removeHashtagImg from 'assets/icons/remove_hashtag.svg';
import { CustomImg } from 'components/common/CustomImg';
import { StyledInput } from 'components/common/Input/StyledInput';
import { Span } from 'components/common/typography/Span';
import { Section } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { noop } from 'constants/functions';
import { onlyLettersAndNumbers } from 'constants/regularExpressions';
import { green, grey9 } from 'constants/styles/colors';
import { useField } from 'formik';
import { countHashtags, hashtagsArrayMaxLength, removeImgDiameter } from 'pages/Upload/Hashtags/constants';
import { HashtagsWrapper, HashtagWrapper, Label, LabelWrapper, OptionalMark } from 'pages/Upload/Hashtags/styles';
import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { BackgroundColor, Color } from 'types/styles';

interface HashtagProps extends BackgroundColor, Color {
    subject: string;
    text?: string;
    onRemove?: (subject: string) => void;
}

export const Hashtag = ({ subject, text = subject, backgroundColor, color, onRemove = noop }: HashtagProps) => (
    <HashtagWrapper backgroundColor={backgroundColor} color={color}>
        #{text}
        <MarginWrapper marginLeft="8px">
            <CustomImg
                pointer
                borderRadius="50%"
                height={removeImgDiameter}
                src={removeHashtagImg}
                width={removeImgDiameter}
                onClick={() => onRemove(subject)}
            />
        </MarginWrapper>
    </HashtagWrapper>
);

interface HashtagsProps extends BackgroundColor, Color {}

export const Hashtags = ({ backgroundColor, color }: HashtagsProps) => {
    const [{ value }, { error }, { setValue }] = useField('hashTags');
    const [hashtag, setHashtag] = useState('');
    const placeholder = '#sneakers';
    const isLimitReached = value.length === hashtagsArrayMaxLength;
    const infoText = countHashtags(hashtagsArrayMaxLength, value.length);
    const infoTextColor = isLimitReached ? green : grey9;

    const onRemove = (subject: string) => setValue(value.filter((item: string) => item !== subject));
    const onAdd = (hashtag: string) =>
        hashtag && setValue([...value.filter((item: string) => item !== hashtag), hashtag]);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onAdd(hashtag);
            setHashtag('');
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        const checkResult = onlyLettersAndNumbers.test(value) || value === '';

        if (checkResult) {
            setHashtag(value.replace(/ /g, '-'));
        }
    };

    return (
        <>
            <LabelWrapper>
                <Label>Tag it!</Label>
                <OptionalMark>(optional)</OptionalMark>
            </LabelWrapper>
            {!isLimitReached && (
                <Section marginBottom="10px">
                    <StyledInput
                        hideError
                        error={error}
                        placeholder="Add hashtags"
                        touched={!!hashtag}
                        value={hashtag}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                    />
                </Section>
            )}
            <HashtagsWrapper>
                {!!value.length ? (
                    value.map((subject: string) => (
                        <Hashtag
                            key={subject}
                            backgroundColor={backgroundColor}
                            color={color}
                            subject={subject}
                            onRemove={onRemove}
                        />
                    ))
                ) : (
                    <Span color="rgba(0,0,0,0.3)" fontSize="16px" fontWeight="600" lineHeight="19px">
                        {placeholder}
                    </Span>
                )}
            </HashtagsWrapper>
            {!!infoText && (
                <Section height="20px" marginBottom="10px">
                    <Span color={infoTextColor} fontSize="12px" fontWeight="400" lineHeight="15px">
                        {infoText}
                    </Span>
                </Section>
            )}
        </>
    );
};
