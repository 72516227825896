import { InformationFooter } from 'components/common/blocks/RegistrationFooter';
import { LinkButton } from 'components/common/buttons/LinkButton';
import { TopBar } from 'components/common/TopBar';
import { SuccessModal } from 'components/modals/SuccessModal';
import { Formik } from 'formik';
import { useSteps } from 'hooks';
import { registrationInitialProps } from 'pages/Sign/formikInitialProps';
import { MainWrapper, StepPageWrapper } from 'pages/Sign/styles';
import React from 'react';
import { Steps, stepsEvents } from 'stores/steps';
import { RegistrationStepName, RegistrationStepsObject, SignStepProps } from 'types/sign';
import { AgreementStep } from './Steps/Agreement';
import { ConfirmationStep } from './Steps/Confirmation';
import { EmailOrPhoneStep } from './Steps/EmailOrPhone';
import { PasswordStep } from './Steps/Password';
import { SmsCodeStep } from './Steps/SmsCode';
import { UsernameStep } from './Steps/Username';

const { setStep } = stepsEvents;

const stepsObj: RegistrationStepsObject = {
    EmailOrPhoneStep,
    UsernameStep,
    PasswordStep,
    SmsCodeStep,
    AgreementStep,
    ConfirmationStep
    // EmailCodeStep,
    // BirthdayStep,
    // GenderStep,
};

export const RegistrationPage = () => {
    const [CurrentComponent, setCurrentStep, goToPrevStep, currentStep] = useSteps<RegistrationStepName, SignStepProps>(
        stepsObj
    );

    const noMainWrapperVerticalPadding = currentStep === 'AgreementStep';

    const goToLoginPage = () => {
        setStep(Steps.Login);
    };

    const goToStart = () => setStep(Steps.VideoRecord);

    return (
        <Formik {...registrationInitialProps}>
            <StepPageWrapper>
                {/* <LoaderModal /> */}
                <SuccessModal withBackgroundImage onClick={goToStart} />
                <TopBar onButtonClick={goToPrevStep} />
                <MainWrapper noVerticalPadding={noMainWrapperVerticalPadding}>
                    <CurrentComponent setCurrentStep={setCurrentStep} />
                    <InformationFooter information="Already have an account?">
                        <LinkButton onClick={goToLoginPage}>Log in</LinkButton>
                    </InformationFooter>
                </MainWrapper>
            </StepPageWrapper>
        </Formik>
    );
};
