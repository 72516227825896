import { Button } from 'components/common/buttons/Button';
import { LoaderModal } from 'components/modals/LoaderModal';
import { grey } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import { yeayLegalNoticeLink } from 'pages/Sign/Registration/Steps/Agreement/constants';
import React, { FC } from 'react';
import { productStores } from 'stores/product';
import { signEffects } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { userEvents } from 'stores/user';
import { SignStepProps } from 'types/sign';
import { getLocaleStandard, getLocaleTag } from 'utils/usefulFunctions';
import { ButtonWrapper, ContentWrapper, Link, MainWrapper, Subtitle, Text, Title } from './styles';

const { createAccount, authenticateUser } = signEffects;

const { setToken, setUser } = userEvents;

export const AgreementStep: FC<SignStepProps> = () => {
    const isLoading = useStore(createAccount.pending);
    const organizationPublicId = useStore(productStores.organizationPublicId);

    const [, { value: username }] = useField('username');
    const [, { value: countryCode }] = useField('countryCode');
    const [, { value: phone }] = useField('phone');
    const [, { value: smsCode }] = useField('smsCode');
    const [, { value: password }] = useField('password');
    // const [, { value: userGender }] = useField('userGender');
    // const [, { value: dateOfBirthAsString }] = useField('dateOfBirth');
    //const dateOfBirth = new Date(parseInt(dateOfBirthAsString));
    let language = navigator.language;
    let locale = language;
    if (locale.length < 3) {
        locale = getLocaleTag(locale);
    }

    if (locale.length < 3 || !locale) locale = `${language}-${language.toUpperCase()}`;

    const handleDecline = () => {
        stepsEvents.setStep(Steps.Login);
    };

    const handleAccept = async () => {
        const accountResponse = await createAccount({
            username,
            facilitatorId: organizationPublicId || '',
            mobileNumber: countryCode + phone,
            smsVerificationCode: smsCode,
            locale: locale,
            language: getLocaleStandard(language),
            acceptLicense: true,
            password
            //dateOfBirth: dateOfBirth?.toISOString(),
            //userGender,
        });

        if (accountResponse && accountResponse.token && accountResponse.user) {
            setToken(accountResponse.token);
            setUser(accountResponse.user);

            authenticateUser({
                mobileNumber: countryCode + phone,
                password
            });
        }
    };

    // useEffect(() => {
    //     isLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isLoading]);

    return (
        <MainWrapper>
            <LoaderModal />
            <Title>To continue, you must accept our end user licensing agreement.</Title>
            <ContentWrapper>
                <Subtitle>
                    The agreement can be seen{' '}
                    <Link href={yeayLegalNoticeLink} target="_blank">
                        HERE
                    </Link>
                </Subtitle>
                <Text>
                    It is important to note that we do not accept nudity or pornography involving people and we also do
                    not accept violence in any form involving humans or animals.
                    <br />
                    <br />
                    Violating content will be removed and the submitter of that material may be banned.
                </Text>
                <ButtonWrapper>
                    <Button backgroundColor={grey} onClick={handleDecline}>
                        Decline
                    </Button>
                    <Button isLoading={isLoading} onClick={handleAccept}>
                        Accept
                    </Button>
                </ButtonWrapper>
            </ContentWrapper>
        </MainWrapper>
    );
};
