import styled from 'styled-components';
import { black, dark, grey3 } from 'constants/styles/colors';

export const StepTitle = styled.h1`
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    font-weight: 600;
    color: ${dark};
`;

export const StepSubTitle = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${grey3};
`;

export const SecureSubTitle = styled(StepSubTitle)`
    color: ${black};
`;
