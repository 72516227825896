import {
    linkTextFontSize,
    linkTextFontWeight,
    linkTextLineHeight
} from 'components/common/buttons/LinkButton/constants';
import { black } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import { TextProperties } from 'types/styles';
import { ButtonHTMLAttributes } from 'react';

export interface StyledLinkButtonProps extends TextProperties, ButtonHTMLAttributes<HTMLButtonElement> {}

export const StyledLinkButton = styled.button<StyledLinkButtonProps>`
    ${disableDefaultButtonStyleMixin};
    width: fit-content;
    //padding: 0px 4px;
    font-size: ${({ fontSize }) => fontSize || linkTextFontSize};
    font-weight: ${({ fontWeight }) => fontWeight || linkTextFontWeight};
    line-height: ${({ lineHeight }) => lineHeight || linkTextLineHeight};
    color: ${({ color }) => color || black};
    text-decoration: underline;
    cursor: pointer;
`;
