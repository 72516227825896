import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { minorTextFontSize, subtitleMarginBottom, titleMarginBottom } from 'pages/Upload/Steps/Final/constants';
import {
    ButtonsWrapper,
    CancelButton,
    ContentWrapper,
    DarkenBg,
    MainWrapper,
    Qualities,
    Stake,
    StyledButton,
    Text,
    Title
} from 'pages/Upload/Steps/Final/styles';
import React, { FC, useState } from 'react';
import { signStores, TypeOfUser } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { videoEffects, videoStores } from 'stores/video';
import { UploadStepProps } from 'types/upload';

type LoadingStatus = '' | 'Submit in progress' | 'Upload in progress';

const { validateContent, invokeNewVideoRequest } = videoEffects;
const { setStep } = stepsEvents;

export const FinalStep: FC<UploadStepProps> = ({ goToPrevStep }) => {
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('');
    const recorderVideoScreen = useStore(videoStores.recorderVideoScreen);
    const typeOfUser = useStore(signStores.typeOfUser);

    /* Status of user */
    const isWithoutWallet = typeOfUser === TypeOfUser.withoutWallet;
    const isWithoutAccount = typeOfUser === TypeOfUser.withoutAuthorization;
    const hasFreeStakes = typeOfUser === TypeOfUser.hasFreeStakes;
    const isNoFreeStakeAndZeroWOMBalance = typeOfUser === TypeOfUser.withoutFreeStakesAndZeroWomBalance;
    const isNoFreeStakeAndPositiveWOMBalance = typeOfUser === TypeOfUser.withoutFreeStakesAndPositiveWomBalance;
    const isUploadOnly = isWithoutAccount || isWithoutWallet || isNoFreeStakeAndZeroWOMBalance;

    const goToThankYouStep = () => {
        setStep(Steps.ThankYou);
    };

    const handleUpload = async () => {
        setLoadingStatus('Upload in progress');

        const { videoId } = await invokeNewVideoRequest();

        if (videoId) {
            setLoadingStatus('');
            goToThankYouStep();
        }
    };

    const submitVideo = async () => {
        const { videoId } = await invokeNewVideoRequest();

        if (videoId) {
            const validationResponse = await validateContent({
                contentId: videoId,
                stakeAmount: 1, //* 0
                requestStakingPromotion: !isNoFreeStakeAndPositiveWOMBalance, //*  false
                holdUntilApproved: true
            });

            if (validationResponse) {
                setLoadingStatus('');
                goToThankYouStep();
            }
        }
    };

    const handleSubmit = async () => {
        setLoadingStatus('Submit in progress');

        await submitVideo();
    };

    return (
        <MainWrapper poster={recorderVideoScreen}>
            <DarkenBg />
            <ContentWrapper>
                <MarginWrapper marginBottom="20px">
                    <Title>Video Upload</Title>
                </MarginWrapper>
                {isWithoutAccount || isWithoutWallet ? (
                    <Text>
                        You must install the YEAY app and login using the same credentials you used to register. Once
                        logged in, you will need to create a WOM Wallet and then submit your video for approval.
                    </Text>
                ) : (
                    <>
                        <MarginWrapper marginBottom={titleMarginBottom}>
                            <Text>
                                If you would like to earn WOM tokens for having people watch and engage with your video,
                                we need to have it rated for
                            </Text>
                        </MarginWrapper>
                        <MarginWrapper marginBottom={titleMarginBottom}>
                            <Qualities>
                                <p>Honesty</p>
                                <p>Creativity</p>
                                <p>Positivity</p>
                            </Qualities>
                        </MarginWrapper>
                        <MarginWrapper marginBottom={subtitleMarginBottom}>
                            <Text fontSize={minorTextFontSize}>This review requires a stake of</Text>
                        </MarginWrapper>
                        <MarginWrapper marginBottom={subtitleMarginBottom}>
                            <Stake>1.0</Stake>
                        </MarginWrapper>
                        {hasFreeStakes && <Text fontSize={minorTextFontSize}>This one is on us</Text>}

                        {isNoFreeStakeAndZeroWOMBalance && (
                            <Text>You will need to log into the YEAY app and buy more WOM in order to submit.</Text>
                        )}
                    </>
                )}
            </ContentWrapper>
            <ButtonsWrapper>
                {isUploadOnly ? (
                    <StyledButton
                        backgroundColor={white}
                        isLoading={loadingStatus === 'Upload in progress'}
                        onClick={handleUpload}
                    >
                        Upload only
                    </StyledButton>
                ) : (
                    <StyledButton
                        backgroundColor={white}
                        isLoading={loadingStatus === 'Submit in progress'}
                        onClick={handleSubmit}
                    >
                        Submit
                    </StyledButton>
                )}
                <CancelButton backgroundColor="transparent" color={white} onClick={goToPrevStep}>
                    Cancel
                </CancelButton>
            </ButtonsWrapper>
        </MainWrapper>
    );
};
