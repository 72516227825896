import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import React, { FC } from 'react';
import { FooterSpan, StyledFooter } from './styles';
// import { signEffects } from 'stores/sign';

// const { authorizeWithoutAccount } = signEffects;

interface InformationFooterProps {
    information: string;
}

export const InformationFooter: FC<InformationFooterProps> = ({ information, children }) => (
    <StyledFooter>
        <MarginWrapper marginRight="7px">
            <FooterSpan>{information}</FooterSpan>
        </MarginWrapper>
        {children}
    </StyledFooter>
);
