import { languagesCodes } from 'constants/languages';
import { createEffect, Effect } from 'effector';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { Steps, stepsEvents } from 'stores/steps';

/**
 * Generate unique identificator
 */
export const uuid = () => {
    let dt = new Date().getTime();

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);

        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};

/**
 * Create effect with bad response notifications
 */
export interface BadResponseErrors
    extends YEAY.Error409ConflictResponse,
        YEAY.Error400BadRequest,
        YEAY.Error404NotFoundResponse {}

export function createNotifyingEffect<Params, Done>(config: {
    name?: string;
    handler?: (params: Params) => Promise<Done> | Done;
    sid?: string;
}): Effect<Params, Done> {
    const effect = createEffect(config);

    effect.fail.watch(({ error }: { error: BadResponseErrors }) => {
        if (!error.isSuccess) {
            let message = error.message;

            if (!message || message === 'unknown') {
                message = 'Some error occurred. Please try again.';
            }

            // notificationsEffects.setNotification({
            //     message,
            //     description: ''
            // });

            modalEvents.openAsyncModal({
                title: 'Error',
                errorText: message,
                onOk: () => stepsEvents.setStep(Steps.Welcome)
            });
        }
    });

    return effect;
}
/**
 * Get locale from languagesCodes object
 */
export const getLocaleFromValue = (value: string) => languagesCodes[value] || '';
// let locale = Object.keys(languagesCodes).find(key => languagesCodes[key] === value) || window.navigator.language;

/**
 * Get secured string
 * test@email.com -> ***email.com
 * +79501231212 -> ***12
 * Any string -> ***ng
 * TODO: Adding test (utils.test.ts)
 */
export const secureData = (data: string) =>
    '***' +
    (data.search('@') === -1
        ? data.substring(data.length - 2, data.length)
        : data.substring(data.search('@') + 1, data.length));
