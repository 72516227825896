import { Column, Section } from 'components/wrappers/FlexWrapper';
import { black, grey10, grey6 } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import { Active, Visibility } from 'types/styles';

export const Label = styled.label`
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
`;

export const Selector = styled(Section)<Visibility>`
    justify-content: space-between;
    align-items: center;
    flex-wrap: noWrap;
    height: 60px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 50px;
    border-radius: 10px;
    background-color: ${grey6};
    cursor: pointer;

    ${({ visible }) =>
        visible &&
        css`
            margin-bottom: 0;
            border-radius: 10px 10px 0 0;
        `};
`;

export const ItemSpan = styled.span`
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: ${black};
`;

export const ArrowImage = styled.img<Visibility>`
    width: 15px;
    height: 15px;
    transform: ${({ visible }) => (visible ? `rotate(180deg)` : '0')};
    transition: transform 0.3s;
`;

export const ListWrapper = styled(Column)<Visibility>`
    display: none;
    width: 100%;
    height: 120px;
    border-radius: 0 0 10px 10px;
    background-color: #f9f9f9;
    outline: none;
    overflow-y: scroll;
    cursor: pointer;

    ${({ visible }) =>
        visible &&
        css`
            display: block;
            margin-bottom: 30px;
        `};
`;

export const ListItemWrapper = styled.button.attrs({ type: 'button' })<Active>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    text-align: left;
    background-color: ${({ active }) => active && grey10};
    transition: background-color 0.3s;

    :hover {
        background-color: ${grey6};
    }
`;
