import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { yeayBaseURL } from 'constants/stores';
import { userStores } from 'stores/user';

const yeayAxiosInstance = axios.create();

yeayAxiosInstance.defaults.baseURL = yeayBaseURL();
yeayAxiosInstance.defaults.method = 'POST';
yeayAxiosInstance.interceptors.response.use(
    config => config.data,
    config => Promise.reject(config?.response?.data ?? config)
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const request: AxiosPromise<T> = yeayAxiosInstance({
        ...config,
        headers: withToken
            ? {
                  Authorization: `Bearer ${userStores.token.getState() || process.env.REACT_APP_AUTH_TOKEN}`,
                  ...config.headers
              }
            : { ...config.headers }
    });

    return (request as any) as Promise<T>;
};
