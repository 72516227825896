import { InformationFooter } from 'components/common/blocks/RegistrationFooter';
import { LinkButton } from 'components/common/buttons/LinkButton';
import { TopBar } from 'components/common/TopBar';
import { Formik } from 'formik';
import { useSteps } from 'hooks';
import { loginInitialProps } from 'pages/Sign/formikInitialProps';
import { MainWrapper, StepPageWrapper } from 'pages/Sign/styles';
import React, { useEffect } from 'react';
import { signEvents } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { LoginStepName, LoginStepsObject, SignStepProps } from 'types/sign';
import { EmailOrPhoneStep } from './Steps/EmailOrPhone';

const stepsObj: LoginStepsObject = {
    EmailOrPhoneStep
};

export const LoginPage = () => {
    const [CurrentComponent, setCurrentStep, goToPrevStep] = useSteps<LoginStepName, SignStepProps>(stepsObj);

    // Unmount cleanup
    useEffect(
        () => () => {
            signEvents.changeChosenSignInput('Phone');
        },
        []
    );

    const goToRegistrationPage = () => {
        stepsEvents.setStep(Steps.Registration);
    };

    return (
        <Formik {...loginInitialProps}>
            <StepPageWrapper>
                {/* <LoaderModal /> */}

                <TopBar onButtonClick={goToPrevStep} />
                <MainWrapper>
                    <CurrentComponent setCurrentStep={setCurrentStep} />
                    <InformationFooter information="Don’t have an account?">
                        <LinkButton onClick={goToRegistrationPage}>Sign Up</LinkButton>
                    </InformationFooter>
                </MainWrapper>
            </StepPageWrapper>
        </Formik>
    );
};
