import axios from './axios';
import { yeayBaseURL } from '../constants/stores';

export const createAccountAnonymous = (data: YEAY.UserCreateAnonymousAccountRequest) =>
    axios<YEAY.UserJwtTokenResponse>(
        {
            url: '/user/create-account-anonymous',
            baseURL: yeayBaseURL(),
            data
        },
        false
    );
