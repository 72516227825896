import preloaderSvgWhite from 'assets/preloader-white.svg';
import preloaderSvgYeay from 'assets/preloader.svg';
import { StyledButton } from 'components/common/buttons/Button/styles';
import { StyledButtonProps } from 'components/common/buttons/Button/types';
import { CustomImg } from 'components/common/CustomImg';
import React, { ButtonHTMLAttributes, FC } from 'react';
import { NoopClick } from 'types';
import { Disabled, Loading } from 'types/styles';

export interface ButtonProps extends StyledButtonProps, Disabled, Loading, ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: NoopClick;
}

export const Button: FC<ButtonProps> = ({ children, isLoading, backgroundColor, ...props }) => {
    const src = backgroundColor ? preloaderSvgYeay : preloaderSvgWhite;

    return (
        <StyledButton backgroundColor={backgroundColor} isLoading={isLoading} {...props}>
            {isLoading ? <CustomImg src={src} /> : children}
        </StyledButton>
    );
};
