import { ResetTimer } from 'components/common/blocks/ResetTimer';
import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { CodeField } from 'components/Formik/SmsCodeField';
import { Section } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import React, { FC, FormEvent } from 'react';
import { signEffects } from 'stores/sign';
import { SignStepProps } from 'types/sign';

const { sendSmsCode, checkSmsCode } = signEffects;

export const SmsCodeStep: FC<SignStepProps> = ({ setCurrentStep }) => {
    const [, /*{ value: smsCodeValue }*/ { error: smsCodeError }, smsCodeHelpers] = useField('smsCode');
    const [{ value: phoneNumber }] = useField('phone');
    const [, { value: countryCode }] = useField('countryCode');

    const isCheckPending = useStore(checkSmsCode.pending);
    const isSendPending = useStore(sendSmsCode.pending);
    const isLoading = isCheckPending || isSendPending;

    const sendNewCode = async () => {
        await sendSmsCode(countryCode + phoneNumber);

        smsCodeHelpers.setValue('');
    };

    // useEffect(() => {
    //     isLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isLoading]);

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (smsCodeError) {
            return;
        }

        setCurrentStep('UsernameStep');
    };

    return (
        <Form onSubmit={handleFormSubmit}>
            <MarginWrapper marginBottom="50px">
                <TitleBlock
                    secure={phoneNumber}
                    subtitle="We sent a code to your phone number"
                    title="Enter 6 Digit Code"
                />
            </MarginWrapper>
            <Section>
                <CodeField name="smsCode" />
            </Section>
            <Section justifyCenter marginBottom="33px" marginTop="40px">
                <Button disabled={!!smsCodeError} height="48px" isLoading={isLoading} type="submit">
                    Next
                </Button>
            </Section>
            <Section justifyCenter>
                <ResetTimer timer={120} onAction={sendNewCode}>
                    Resend code
                </ResetTimer>
            </Section>
            {/*<NextButtonWrapper>*/}
            {/*    <NavigationButton disabled={error} type="submit" />*/}
            {/*</NextButtonWrapper>*/}
        </Form>
    );
};
