import * as media from './media';
import * as playlist from './playlist';
import * as product from './product';
import * as sign from './sign';
import * as user from './user';
import * as video from './video';
import * as wom from './wom';
import { womServices } from './womServices';

export const API = {
    user,
    playlist,
    sign,
    product,
    video,
    media,
    wom,
    womServices
};
