import { ResetTimer } from 'components/common/blocks/ResetTimer';
import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { SecurityCodeField } from 'components/Formik/SecurityCodeField';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import React, { FC, FormEvent } from 'react';
import { signEffects } from 'stores/sign';
import { SignStepProps } from 'types/sign';

const { sendForgottenPasswordEmail } = signEffects;

export const SecurityCodeStep: FC<SignStepProps> = ({ setCurrentStep }) => {
    const [, { error: securityCodeError }] = useField('securityCode');
    const [, { value: emailValue }, { setError }] = useField('email');
    const isLoading = useStore(sendForgottenPasswordEmail.pending);

    const onClick = async () => {
        const response = await sendForgottenPasswordEmail(emailValue);

        if (response && response.isSuccess) {
            setCurrentStep('SecurityCodeStep');
        } else if (response) {
            setError("Hey sorry, but we couldn't find your email address 😔");
        }
    };

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (securityCodeError) {
            return;
        }

        setCurrentStep('NewPasswordStep');
    };

    // useEffect(() => {
    //     isLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isLoading]);

    return (
        <Form onSubmit={onSubmit}>
            <Column alignCenter>
                <TitleBlock
                    secure={emailValue}
                    subtitle="We sent a code to your email address"
                    title="Enter Security Code"
                />
                <Section marginBottom="40px" marginTop="92px">
                    <SecurityCodeField />
                </Section>
                <Button disabled={!!securityCodeError} isLoading={isLoading}>
                    Next
                </Button>
                <MarginWrapper marginTop="35px">
                    <ResetTimer timer={120} onAction={onClick}>
                        Resend Code
                    </ResetTimer>
                </MarginWrapper>
            </Column>
        </Form>
    );
};
