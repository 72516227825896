import { black, green, grey8 } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';
import { BackgroundColor, Color } from 'types/styles';

export const LabelWrapper = styled.label``;

export const Label = styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
`;

export const OptionalMark = styled.span`
    font-family: inherit;
    font-size: 11px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    opacity: 0.5;
    margin-left: 5px;
`;

export const HashtagsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 46px;
    max-height: 120px;
    border-radius: 8px;
    padding: 0 5px;
    background-color: ${grey8};
    overflow-y: auto;
    margin-bottom: 8px;
`;

interface HashtagWrapperProps extends BackgroundColor, Color {}

export const HashtagWrapper = styled.div<HashtagWrapperProps>`
    ${flexCenter};
    width: fit-content;
    height: 30px;
    border-radius: 15px;
    padding: 0 10px;
    color: ${({ color }) => color || black};
    background-color: ${({ backgroundColor }) => backgroundColor || green};
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.21333329379558563px;
    text-align: left;
    margin: 5px 5px 5px 0px;
`;
