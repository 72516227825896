import { Button } from 'components/common/buttons/Button';
import { LoaderModal } from 'components/modals/LoaderModal';
import { dark, grey, white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { ButtonsWrapper, Email, MainWrapper, Text } from 'pages/Sign/Confirmation/styles';
import React, { FC, useEffect } from 'react';
import { loaderModal } from 'stores/initialize/initialize.modal.store';
import { notificationsEffects } from 'stores/notifications';
import { signEffects } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { userStores } from 'stores/user';

const { getCurrentAuthorization } = signEffects;
const { setStep } = stepsEvents;

export const ConfirmationPage: FC = () => {
    const isAuthCheckLoading = useStore(getCurrentAuthorization.pending);
    // const isWOMCreationLoading = useStore(createWOMWallet.pending);
    // const isConfirmationLoading = isAuthCheckLoading || isWOMCreationLoading;
    const isConfirmationLoading = isAuthCheckLoading;
    // const isResendingLoading = false;

    const { userId, email } = useStore(userStores.user);

    // const resend = () => {
    // };

    const reRegister = () => {
        setStep(Steps.Registration);
    };

    const confirm = async () => {
        const authResponse = await signEffects.getCurrentAuthorization(userId);

        if (authResponse && authResponse.isAccountVerified && authResponse.isEmailValidated) {
            // const walletResponse = await signEffects.createWOMWallet(true); // at least 1 argument needed

            // if (walletResponse) {
            //     setStep(Steps.Upload);
            // }
            setStep(Steps.Upload);
        } else if (authResponse) {
            notificationsEffects.setNotification({
                message: 'Sorry, not confirmed yet',
                description: ''
            });
        }
    };

    useEffect(() => {
        isConfirmationLoading ? loaderModal.openModal() : loaderModal.closeModal();
    }, [isConfirmationLoading]);

    return (
        <MainWrapper>
            <LoaderModal />
            <div>
                <Text>We have sent a confirmation email to</Text>
                <Email>{email}</Email>
                <Text>Please confirm your email before continuing</Text>
            </div>
            <ButtonsWrapper>
                {/*<Button isLoading={isResendingLoading} view="green" onClick={resend}>*/}
                {/*    RESEND*/}
                {/*</Button>*/}
                <Button backgroundColor={grey} onClick={reRegister}>
                    RE-REGISTER
                </Button>
                <Button backgroundColor={dark} color={white} /*isLoading={isConfirmationLoading}*/ onClick={confirm}>
                    I ALREADY CONFIRMED
                </Button>
            </ButtonsWrapper>
        </MainWrapper>
    );
};
