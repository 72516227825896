import axios from './axios';
import { getRecordedDate } from 'utils/video';
import { isSafari } from 'utils/mobile';
import { yeayBaseURL } from '../constants/stores';

export const upload = (videoId: string, videoStream: Blob) => {
    const bodyFormData = new FormData();

    // TODO: change name to recorded video time
    const fileName = `${getRecordedDate()}.${isSafari ? 'mp4' : 'webm'}`;
    bodyFormData.append('fileName', new File([videoStream], fileName));

    return axios<YEAY.MessageResponseBase>({
        url: `/media/upload/${videoId}`,
        baseURL: yeayBaseURL(),
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};
