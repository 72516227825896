import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { UsernameField } from 'components/Formik/UsernameField';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import React, { FC, FormEvent } from 'react';
import { signEffects } from 'stores/sign';
import { SignStepProps } from 'types/sign';

const { checkUsernameExistence } = signEffects;

export const UsernameStep: FC<SignStepProps> = ({ setCurrentStep }) => {
    const [, /*{ value }*/ { error }] = useField('username');
    const isCheckPending = useStore(checkUsernameExistence.pending);

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (error) {
            return;
        }

        setCurrentStep('PasswordStep');
    };

    // useEffect(() => {
    //     isCheckPending ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isCheckPending]);

    return (
        <Form onSubmit={handleFormSubmit}>
            <Column alignCenter>
                <TitleBlock subtitle="Let’s start with a unique username." title="Add Username" />

                <Section marginBottom="40px" marginTop="120px">
                    <UsernameField />
                </Section>

                <Button disabled={!!error} height="48px" isLoading={isCheckPending}>
                    Next
                </Button>
            </Column>
        </Form>
    );
};
