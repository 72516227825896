import { Section } from 'components/wrappers/FlexWrapper';
import { grey3, grey8 } from 'constants/styles/colors';
import styled from 'styled-components';

export const StyledFooter = styled(Section)`
    position: sticky;
    bottom: 0;
    height: 61px;
    width: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${grey8};
`;

export const FooterSpan = styled.span`
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: ${grey3};
`;
