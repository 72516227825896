import { Fallback } from 'components/common/Fallback';
import { SafariFallback } from 'pages/VideoRecord/safariFallback';
import React, { lazy, Suspense } from 'react';
import { isSafari } from 'utils/mobile';

const VideoRecord = lazy(() =>
    import('components/common/VideoRecord').then(module => ({ default: module.VideoRecord }))
);

export const VideoRecordPage = () => {
    // @ts-ignore
    const mediaRecorderEnable = typeof window.MediaRecorder === 'function';

    return (
        <Suspense fallback={<Fallback />}>
            {isSafari && !mediaRecorderEnable ? <SafariFallback /> : <VideoRecord />}
        </Suspense>
    );
};
