import { inputStyleMixin } from 'components/common/Input/styles';
import { Span } from 'components/common/typography/Span';
import { black, errorColor, green, grey5 } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin, disableDefaultInputStyleMixin, ellipsisMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import {
    inputHeight,
    inputInformationSpanHeight,
    inputTextVerticalPadding,
    showPasswordButtonHeight
} from './constants';

export interface StyledInputProps {
    error?: string;
    touched?: boolean;
    value?: string;
}

export const StyledInputWrapper = styled.input<StyledInputProps>`
    ${disableDefaultInputStyleMixin};
    ${inputStyleMixin};
    ${ellipsisMixin};
    width: 100%;
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            pointer-events: none;
        `};
    border-bottom: 2px solid ${grey5};

    :focus {
        border-color: ${black};
    }

    &.border_color {
        ${({ touched, error }) => touched && `border-color: ${error ? errorColor : green}`};
    }
`;

export const Label = styled.label`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
`;

export const InputInformationSpan = styled(Span)<StyledInputProps>`
    font-family: inherit;
    font-size: 10px;
    text-align: center;
    line-height: ${inputInformationSpanHeight};
    height: ${inputInformationSpanHeight};
    margin: 10px;
    color: ${({ error }) => (error ? errorColor : green)};
`;

export const ShowPasswordButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    font-family: inherit;
    font-size: 10px;
    line-height: 12px;
    height: ${showPasswordButtonHeight};
`;

export const InformationInputWrapper = styled.div`
    position: absolute;
    right: ${inputTextVerticalPadding};
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${inputHeight};
`;
