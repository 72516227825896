import { inputDiameter } from 'components/common/Input/CodeInput/constants';
import { CodeInputProps } from 'components/common/Input/CodeInput/types';
import { black, grey, grey8 } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const VisibleCodeInput = styled.div<CodeInputProps>`
    ${flexCenter}
    width: 35px;
    height: 30px;
    font-weight: 600;
    line-height: 15px;
    margin-right: 6px;
    transition: 0.3s;
    color: ${({ color }) => color || black};
    border: none;
    border-bottom: 2px solid ${({ borderColor }) => borderColor || grey};
    cursor: pointer;
`;

export const Label = styled.label`
    ${flexCenter};
    position: relative;
    width: 100%;
    padding: 5px 0px;
`;

export const InputWrapper = styled.div<CodeInputProps>`
    height: ${inputDiameter};
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    :hover {
        background-color: ${grey8};
    }
`;
