import { Button } from 'components/common/buttons/Button';
import { Span } from 'components/common/typography/Span';
import { textFontWeight } from 'components/modals/AsyncModal/constants';
import { ButtonsWrapper, Modal, TextWrapper } from 'components/modals/AsyncModal/styles';
import { Column } from 'components/wrappers/FlexWrapper';
import { black, grey3, white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React from 'react';
import { modalEvents, modalStores } from 'stores/initialize/initialize.modal.store';
import { ModalWrapper } from '../ModalWrapper';

export const AsyncModal = () => {
    const { visible, title, successText, errorText, onOk, closeText, okText } = useStore(modalStores.asyncModal);
    const buttonWidth = closeText ? '110px' : '200px';
    const buttonBorderRadius = closeText && '10px';

    const onOkClick = () => {
        onOk?.();
        modalEvents.closeAsyncModal();
    };
    const onCloseClick = () => modalEvents.closeAsyncModal();

    //useNonScrolledBackground(visible);

    return (
        <ModalWrapper visible={visible}>
            <Modal>
                {/* <AbsoluteWrapper right="15px" top="15px">
                    <CustomImg
                        pointer
                        height={closeIconDiameter}
                        src={closeIcon}
                        width={closeIconDiameter}
                        onClick={onCloseClick}
                    />
                </AbsoluteWrapper> */}
                <Column alignCenter justifyBetween height="100%">
                    <Span alignCenter fontSize="18px" fontWeight={textFontWeight} lineHeight="21px">
                        {title}
                    </Span>
                    <TextWrapper>
                        <Span
                            alignCenter
                            color={errorText ? grey3 : ''}
                            fontSize="15px"
                            fontWeight={errorText ? '400' : textFontWeight}
                            letterSpacing="1px"
                            lineHeight="18px"
                        >
                            {successText || errorText}
                        </Span>
                    </TextWrapper>
                    <ButtonsWrapper withCloseButton={!!closeText}>
                        {!!closeText && (
                            <Button
                                backgroundColor={black}
                                borderRadius={buttonBorderRadius}
                                color={white}
                                fontSize="14px"
                                fontWeight={textFontWeight}
                                height="52px"
                                width={buttonWidth}
                                onClick={onCloseClick}
                            >
                                {closeText}
                            </Button>
                        )}
                        <Button
                            borderRadius={buttonBorderRadius}
                            fontSize="14px"
                            fontWeight="600"
                            height="52px"
                            width={buttonWidth}
                            onClick={onOkClick}
                        >
                            {okText}
                        </Button>
                    </ButtonsWrapper>
                </Column>
            </Modal>
        </ModalWrapper>
    );
};
