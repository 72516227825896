import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const SimpleButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    font-family: inherit;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #000000;

    :disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;
