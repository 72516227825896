import { grey4, grey5 } from 'constants/styles/colors';
import { ReactNode } from 'react';

export const inActiveTitleColor = grey4;
export const inActiveBorderColor = grey5;

export type LoginInputType = 'Phone' | 'Email' | 'Username';

export interface InputAccountType {
    type: LoginInputType;
    input: ReactNode;
    title: string;
}

export const inputTypes: [LoginInputType, LoginInputType, LoginInputType] = ['Phone', 'Email', 'Username'];
