import { createEvent, createStore } from 'effector';
import { uuid } from 'utils/common';

interface Notification {
    message: string;
    description: string;
    id: string;
}

// wait 3 sec.
const wait = () =>
    new Promise(resolve => {
        setTimeout(resolve, 3000);
    });

// remove by id
const removeNotification = createEvent<string>();
const setNotificationEvent = createEvent<Notification>();

const notifications = createStore<Notification[]>([])
    .on(setNotificationEvent, (state, notification) => [...state, notification])
    .on(removeNotification, (state, notificationId) => state.filter(({ id }) => id !== notificationId));

const setNotification = (notification: Omit<Notification, 'id'>) => {
    const id = uuid();

    setNotificationEvent({
        ...notification,
        id
    });

    wait().then(() => removeNotification(id));
};

export const notificationsStore = { notifications };

export const notificationsEffects = { setNotification, removeNotification };
