import { StyledInput } from 'components/common/Input/StyledInput';
import { Section } from 'components/wrappers/FlexWrapper';
import { useField } from 'formik';
import React from 'react';
import { BorderColor } from 'types/styles';

interface Props extends BorderColor {
    name: string;
    label: string;
    disabled?: boolean;
    placeholder?: string;
    value?: string;
}

export const UnderlinedField = ({ name, placeholder = 'input data', borderColor, ...inputProps }: Props) => {
    const [field, { touched, error }] = useField(name);

    return (
        <Section marginBottom="5px">
            <StyledInput
                {...field}
                {...inputProps}
                borderColor={borderColor}
                error={error}
                placeholder={placeholder}
                touched={touched}
            />
        </Section>
    );
};
