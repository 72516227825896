import React from 'react';
import { useStore } from 'effector-react';
import { loaderModal } from 'stores/initialize/initialize.modal.store';
import { Modal } from 'components/modals/LoaderModal/styles';
import { Section } from 'components/wrappers/FlexWrapper';
import { Loader } from 'components/common/blocks/Loader';
import { ModalWrapper } from '../ModalWrapper';

export const LoaderModal = () => {
    const { visible } = useStore(loaderModal.modal);

    return (
        <ModalWrapper visible={visible}>
            <Modal>
                <Section alignCenter justifyCenter height="100%">
                    <Loader diameter="40px" />
                </Section>
            </Modal>
        </ModalWrapper>
    );
};
