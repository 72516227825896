import {
    defaultButtonText,
    defaultMessage,
    defaultMessageMargin,
    defaultTitle
} from 'components/modals/SuccessModal/constants';
import {
    MessageSpan,
    Modal,
    ModalButton,
    TitleSpan,
    TitleWrapper,
    TitleWrapperProps
} from 'components/modals/SuccessModal/styles';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { useStore } from 'effector-react';
import React from 'react';
import { successModal } from 'stores/initialize/initialize.modal.store';
import { NoopClick } from 'types';
import { Sizes } from 'types/styles';
import { ModalWrapper } from '../ModalWrapper';

interface SuccessModalProps extends TitleWrapperProps, Sizes {
    onClick: NoopClick;
    title?: string | JSX.Element;
    message?: string | JSX.Element;
    messageMargin?: string;
    buttonText?: string;
    buttonTextColor?: string;
    buttonBackgroundColor?: string;
}

export const SuccessModal = ({
    title,
    message,
    onClick,
    width,
    withBackgroundImage = false,
    messageMargin = defaultMessageMargin,
    buttonText,
    buttonTextColor,
    buttonBackgroundColor
}: SuccessModalProps) => {
    const { visible } = useStore(successModal.modal);

    const onButtonClick = () => {
        successModal.closeModal();
        onClick();
    };

    return (
        <ModalWrapper visible={visible}>
            <Modal width={width}>
                <Column alignCenter height="100%">
                    <TitleWrapper withBackgroundImage={withBackgroundImage}>
                        {title || <TitleSpan>{defaultTitle}</TitleSpan>}
                    </TitleWrapper>

                    <Section justifyCenter margin={messageMargin}>
                        {message || <MessageSpan>{defaultMessage}</MessageSpan>}
                    </Section>
                    <ModalButton
                        backgroundColor={buttonBackgroundColor}
                        color={buttonTextColor}
                        onClick={onButtonClick}
                    >
                        {buttonText || defaultButtonText}
                    </ModalButton>
                </Column>
            </Modal>
        </ModalWrapper>
    );
};
