import {
    codeLengthMessage,
    disposableEmailMessage,
    emailAlreadyExistsMessage,
    insufficientUsernameMessage,
    inValidEmailMessage,
    invalidPhoneNumberFormatMessage,
    inValidUsernameMessage,
    notFoundPhoneNumberMessage,
    phoneNumberAlreadyExistsMessage,
    requiredFieldMessage,
    usernameAlreadyTakenMessage
} from 'constants/messages';
import { onlyPlusAndNumbersRegExp } from 'constants/regularExpressions';
import { FormikErrors, FormikValues } from 'formik';
import { checkListArray } from 'pages/Sign/PasswordForgotten/Steps/NewPassword/constants';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { signEffects } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { isEmailDisposable } from 'utils/isEmailDisposable';

//TODO: prevent every field validation
interface FormErrors {
    username?: string;
    phone?: string;
    smsCode?: string;
    // emailCode?: string;
    email?: string;
    password?: string;
    securityCode?: string;
    dateOfBirth?: string;
    userGender?: number;
}

type SingType = 'login' | 'registration' | 'forgotten';

//
/*** Username ***/
//
const validateUsername = async (username: string) => {
    if (!username || username === '@') {
        return requiredFieldMessage;
    }
    if (username[0] !== '@') {
        username = `@${username}`;
    }
    if (username.length < 7) {
        return insufficientUsernameMessage;
    }

    // Formik catches 404 error
    const exists = await signEffects.checkUsernameExistence(username.slice(1));

    if (exists) {
        return usernameAlreadyTakenMessage;
    } else {
        return '';
    }
};

//
/*** Phone ***/
//
const validatePhone = async (phone: string, countryCode: string) => {
    const regExp = onlyPlusAndNumbersRegExp;
    const regExpCheckResult = regExp.test(countryCode + phone);

    if (!phone) {
        return requiredFieldMessage;
    }

    if (!regExpCheckResult) {
        return invalidPhoneNumberFormatMessage;
    }

    const result = await signEffects.analyzeMobileNumber(countryCode + phone);

    // 0 = Exists
    // 1 = NotFoundInSystem
    // -2 = PendingVerification
    // -1 = InvalidFormat
    switch (result) {
        case 0:
            return phoneNumberAlreadyExistsMessage;
        case 1:
        case -2:
            return '';
        case -1:
            return invalidPhoneNumberFormatMessage;
        default:
            return requiredFieldMessage;
    }
};

const loginValidatePhone = async (phone: string, countryCode: string) => {
    const regExp = onlyPlusAndNumbersRegExp;
    const regExpCheckResult = regExp.test(countryCode + phone);

    if (!phone) {
        return requiredFieldMessage;
    }

    if (!regExpCheckResult) {
        return invalidPhoneNumberFormatMessage;
    }

    const result = await signEffects.analyzeMobileNumber(countryCode + phone);

    // 0 = Exists
    // 1 = NotFoundInSystem
    // -2 = PendingVerification
    // -1 = InvalidFormat
    switch (result) {
        case -1:
            return invalidPhoneNumberFormatMessage;
        case 1:
            return notFoundPhoneNumberMessage;
        default:
            return '';
    }
};

//
/*** Email ***/
//
const validateEmail = async (email: string, signType?: SingType) => {
    if (!email) {
        return requiredFieldMessage;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
        return inValidEmailMessage;
    }
    if (isEmailDisposable(email)) {
        return disposableEmailMessage;
    }
    if (signType === 'registration') {
        // 3 = exists
        const result = await signEffects.analyzeEmail(email);

        if (result === 3) {
            modalEvents.openAsyncModal({
                title: 'Email already exists',
                errorText:
                    'You can log in to the account associated with this email address or continue with a different email address.',

                onOk: () => stepsEvents.setStep(Steps.Login),
                okText: 'Log In',
                closeText: 'Ok'
            });

            return emailAlreadyExistsMessage;
        }
    }

    return '';
};

const loginValidateEmail = async (email: string) => {
    if (!email) {
        return requiredFieldMessage;
    }
    if (isEmailDisposable(email)) {
        return disposableEmailMessage;
    }

    const exists = await signEffects.checkUsernameExistence(email);

    if (exists) {
        return '';
    } else {
        return inValidUsernameMessage;
    }
};

//
/*** Sms Code ***/
//
const validateSmsCode = async (code: string, mobileNumber: string) => {
    if (code?.toString().length !== 6) {
        return codeLengthMessage;
    }

    const isCodeValid = await signEffects.checkSmsCode({ code, mobileNumber });

    if (isCodeValid === true) {
        return '';
    } else if (isCodeValid === false) {
        return 'Invalid';
    } else return isCodeValid;
};

//
/*** Email Code ***/
//
// const validateEmailCode = async (code: string, email: string) => {
//     if (code.length !== 6) {
//         return codeLengthMessage;
//     }
//
//     // const isCodeValid = await signEffects.checkSmsCode({ code, mobileNumber });
//     /* Mock */
//     const isCodeValid = true;
//
//     if (isCodeValid === true) {
//         return '';
//     } else if (isCodeValid === false) {
//         return 'Invalid';
//     } else return isCodeValid;
// };

//
/*** Password ***/
//
const validatePassword = (password: string, signType?: SingType) => {
    if (signType === 'login') {
        return password.length ? '' : requiredFieldMessage;
    }

    for (let item of checkListArray) if (!item.regExp.test(password)) return item.text;
};

//
/*** Date of Birth ***/
//
// const validateDateOfBirth = (dateISOString: string) => {
//     if (!dateISOString) return requiredFieldMessage;

//     const dateOfBirth = new Date(dateISOString);
//     //creating date 13 years ago:
//     // const year13YearsAgo = new Date().getFullYear() - 13;
//     // const month = new Date().getMonth();
//     // const day = new Date().getDate();
//     //
//     // const date13YearsAgo = new Date(year13YearsAgo, month, day);

//     return dateOfBirth <= getDateBeforeAndReturn(13) ? '' : invalidDateOfBirthMessage;
// };

//
/*** User Gender ***/
//

// const validateUserGender = (userGender: number) => (userGender >= 0 ? '' : requiredFieldMessage);

//
/*** Security Code ***/
//
const validateSecurityCode = (code: string) => (code.length ? '' : requiredFieldMessage);

//
/*** Main Forms ***/
//
export const validateRegistrationForm = async ({
    // email,
    // dateOfBirth,
    // userGender,
    // emailCode,
    username,
    countryCode,
    phone,
    smsCode,
    password
}: FormikValues) => {
    const errors: FormikErrors<FormErrors> = {};

    errors.username = await validateUsername(username);
    errors.phone = await validatePhone(phone, countryCode);
    errors.smsCode = await validateSmsCode(smsCode, countryCode + phone);
    errors.password = validatePassword(password);
    // errors.email = await validateEmail(email, 'registration');
    // errors.emailCode = await validateEmailCode(emailCode, email);
    // errors.dateOfBirth = validateDateOfBirth(dateOfBirth);
    // errors.userGender = validateUserGender(userGender);

    return errors;
};

export const validateLoginForm = async ({ countryCode, phone, smsCode, email, password }: FormikValues) => {
    const errors: FormikErrors<FormErrors> = {};

    errors.phone = await loginValidatePhone(phone, countryCode);
    errors.email = await loginValidateEmail(email);
    errors.smsCode = await validateSmsCode(smsCode, countryCode + phone);
    errors.password = validatePassword(password, 'login');

    return errors;
};

export const validatePasswordForgotten = async ({
    email,
    securityCode,
    password,
    countryCode,
    phone,
    smsCode
}: FormikValues) => {
    const errors: FormikErrors<FormErrors> = {};

    errors.phone = await loginValidatePhone(phone, countryCode);
    errors.email = await validateEmail(email);
    errors.password = await validatePassword(password);
    errors.smsCode = await validateSmsCode(smsCode, countryCode + phone);
    errors.securityCode = await validateSecurityCode(securityCode);

    return errors;
};
