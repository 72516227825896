import { Button } from 'components/common/buttons/Button';
import { Span } from 'components/common/typography/Span';
import { LoaderModal } from 'components/modals/LoaderModal';
import { Column } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { white } from 'constants/styles/colors';
import { buttonWidth } from 'pages/Sign/Welcome/constants';
import React from 'react';
import { signEffects } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { SpanWrapper, WelcomeWrapper } from './styles';

const { setStep } = stepsEvents;
const { authorizeWithoutAccount } = signEffects;

export const WelcomeUserPage = () => {
    const goToLoginPage = () => setStep(Steps.Login);
    const goToRegistrationPage = () => setStep(Steps.Registration);
    const goToUploadWithoutAccount = () => authorizeWithoutAccount({});

    return (
        <WelcomeWrapper>
            <LoaderModal />
            <>
                <SpanWrapper>
                    <Span
                        alignCenter
                        color={white}
                        fontSize="18px"
                        fontWeight="600"
                        letterSpacing="1px"
                        lineHeight="28px"
                    >
                        Unlock your recommendation to earn reward. People around the world could benefit from your
                        experience, and you can be rewarded for sharing.
                    </Span>
                </SpanWrapper>
                <Column>
                    <MarginWrapper marginBottom="20px">
                        <Button width={buttonWidth} onClick={goToRegistrationPage}>
                            <Span fontSize="14px" fontWeight="600" lineHeight="17px">
                                Sign up
                            </Span>
                        </Button>
                    </MarginWrapper>
                    <MarginWrapper marginBottom="20px">
                        <Button backgroundColor={white} borderColor={white} width={buttonWidth} onClick={goToLoginPage}>
                            <Span fontSize="14px" fontWeight="600" lineHeight="17px">
                                Log in
                            </Span>
                        </Button>
                    </MarginWrapper>
                    <Button
                        backgroundColor="transparent"
                        borderColor={white}
                        width={buttonWidth}
                        onClick={goToUploadWithoutAccount}
                    >
                        <Span color={white} fontSize="14px" fontWeight="600" lineHeight="17px">
                            Continue without account
                        </Span>
                    </Button>
                </Column>
            </>
        </WelcomeWrapper>
    );
};
