//import loaderBackground from 'assets/loader_background.svg';
import loader from 'assets/loader.svg';
import { CustomImg } from 'components/common/CustomImg';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { Row } from 'components/wrappers/FlexWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import React from 'react';
import { AnimatedLoader } from './styles';

export interface LoaderProps {
    diameter: string;
}

export const Loader = ({ diameter }: LoaderProps) => (
    <Row height={diameter} width={diameter}>
        <RelativeWrapper>
            {/* <AbsoluteWrapper>
                    <CustomImg height={diameter} src={loaderBackground} width={diameter} />
                </AbsoluteWrapper> */}
            <AbsoluteWrapper>
                <AnimatedLoader>
                    <CustomImg height={diameter} src={loader} width={diameter} />
                </AnimatedLoader>
            </AbsoluteWrapper>
        </RelativeWrapper>
    </Row>
);
