import { Section } from 'components/wrappers/FlexWrapper';
import { black, grey8 } from 'constants/styles/colors';
import styled from 'styled-components';

export const AgreementWrapper = styled(Section)`
    box-sizing: border-box;
    padding: 18px 16px;
    background: ${grey8};
    border-radius: 8px;
`;

export const AgreementSpan = styled.span`
    font-family: inherit;
    font-size: 10px;
    line-height: 18px;
    color: #767a9f;
`;

export const Link = styled.a`
    font-weight: 600;
    line-height: 18px;
    color: ${black} !important;
    text-decoration: underline !important;
`;
