import { black } from 'constants/styles/colors';
import styled from 'styled-components';
import { inActiveBorderColor, inActiveTitleColor } from './constants';

export const Selector = styled.ul`
    list-style-type: none;
    width: 100%;
    display: flex;
    justify-content: space-around;
`;

interface SelectorItemProps {
    isActive?: boolean;
}

export const SelectorItem = styled.li<SelectorItemProps>`
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: ${({ isActive }) => (isActive ? black : inActiveTitleColor)};
    cursor: pointer;

    :after {
        content: '';
        margin-top: 24px;
        height: 1px;
        border-bottom: 2px solid ${({ isActive }) => (isActive ? black : inActiveBorderColor)};
    }
`;
