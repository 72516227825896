import { validateUploadForm } from 'pages/Upload/validation';

//const userLanguage = languagesCodes[navigator.language.slice(0, 2)];

const initialValues: YEAY.CreateVideoRequest = {
    brand: '',
    product: '',
    productId: '',
    spokenLanguages: [],
    audioLanguages: [],
    hashTags: []
    // startTimeCode: '',
    // endTimeCode: ''
};

export const uploadInitialProps = {
    // validateOnChange: true,
    // validateOnBlur: true,
    initialValues,
    initialErrors: {},
    // initialTouched: {
    //     brand: false,
    //     product: false,
    //     hashTags: false,
    //     language: false
    // },
    // initialValues: {
    //     brand: '',
    //     product: '',
    //     hashTags: [],
    //     language: userLanguage || 'English'
    // },
    //validateOnBlur: false,
    validate: validateUploadForm,
    onSubmit: () => {}
};
