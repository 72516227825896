import axios from './axios';
import { yeayBaseURL } from '../constants/stores';

export const createWOMWallet = () =>
    axios<YEAY.MessageResponseBase>({
        url: '/wom/create-wom-wallet',
        baseURL: yeayBaseURL()
    });

export const beginValidation = (data: YEAY.WOMStartValidationRequest) =>
    axios<YEAY.ValidationStateResponse>({
        url: '/wom/begin-validation',
        baseURL: yeayBaseURL(),
        data
    });
