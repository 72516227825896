import { black } from 'constants/styles/colors';
import styled from 'styled-components';
import { Color, Opacity, TextProperties } from 'types/styles';

export interface SpanProps extends Color, TextProperties, Opacity {}

export const Span = styled.span<SpanProps>`
    ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`};
    ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle};`};
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
    ${({ fontSize }) => fontSize && `font-size: ${fontSize};`};
    ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`};
    ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`};
    color: ${({ color }) => color || black};
    ${({ noWrap }) => (noWrap ? 'white-space: nowrap' : '')};
    ${({ opacity }) => (opacity ? `opacity: ${opacity};` : ``)};
    ${({ alignCenter }) => (alignCenter ? 'text-align: center' : '')}
`;
