import { white } from 'constants/styles/colors';
import styled from 'styled-components';

export const Layout = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 520px;
    margin: auto;
    background-color: ${white};
`;
