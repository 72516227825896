import { useField } from 'formik';
import React, { FC, useCallback, useEffect } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { InputProps } from '../index';
import { PhoneInputWrapper } from './styles';

const codeContainerClass = 'country-code-container';
const phoneInputClass = 'phone-input';

export const PhoneInput: FC<InputProps> = ({ value = '', ...inputProps }) => {
    const [, { value: countryCodeValue }, countryCodeHelpers] = useField('countryCode');
    const [, { value: countryIdValue }, countryIdHelpers] = useField('countryId');
    const [, { touched, error }, phoneHelpers] = useField('phone');

    let formattedValue = '';

    if (value.includes(countryCodeValue)) {
        formattedValue = value.replace(countryCodeValue, '');
        phoneHelpers.setValue(formattedValue);
    }

    const inputClass = touched ? (error ? 'phone-input_error' : 'phone-input_success') : phoneInputClass;
    const countryCodeClass = touched
        ? error
            ? 'country-code-container country-code-container_error'
            : 'country-code-container country-code-container_success'
        : codeContainerClass;

    // Original component has country code inside input
    // but not inside dropdown button. This function
    // puts code in dropdown button and properly styles component.
    const addCodeToContainer = useCallback((code: string) => {
        const codeContainer = document.querySelector<HTMLElement>(`.${codeContainerClass}`);
        const inputElement = document.querySelector<HTMLElement>(`.${phoneInputClass}`);

        // form-control class prevents
        // form submit by pressing enter
        inputElement?.classList.remove('form-control');

        const flagElement = codeContainer?.querySelector<HTMLElement>('.flag');
        let codeElement = codeContainer?.querySelector<HTMLElement>('.country-code');

        if (!flagElement) return;

        if (!codeElement) {
            codeElement = document.createElement('div');
            codeElement.classList.add('country-code');
        }
        codeElement.innerText = `${code}`;

        flagElement.prepend(codeElement);
    }, []);

    useEffect(() => {
        if (countryCodeValue) {
            addCodeToContainer(countryCodeValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCodeValue]);

    const handleValidation = (newCountryCode: string, country: object) => {
        const countryObject = country as { iso2: string };

        // Country code fallback
        if (!newCountryCode) {
            countryIdHelpers.setValue('us');

            return true;
        }

        // Fires addCodeToContainer() when rendered
        if (`+${newCountryCode}` !== countryCodeValue) {
            // TODO: error
            countryCodeHelpers.setValue(`+${newCountryCode}`);
            countryIdHelpers.setValue(countryObject.iso2);
        }

        // If no return there is error
        return true;
    };

    return (
        <PhoneInputWrapper borderColor={inputProps.borderColor}>
            <ReactPhoneInput
                buttonClass={countryCodeClass}
                country={countryIdValue}
                inputClass={inputClass}
                inputProps={{
                    ...inputProps,
                    type: 'tel',
                    placeholder: 'Phone number',
                    value: formattedValue || value
                }}
                isValid={handleValidation}
            />
        </PhoneInputWrapper>
    );
};
