export const removeImgDiameter = '21px';
export const hashtagsArrayMaxLength = 3;

export const countHashtags = (maxLength: number, arrayLength: number) => {
    if (!arrayLength) {
        return '';
    } else if (arrayLength < maxLength) {
        return `You can add ${maxLength - arrayLength} more`;
    } else {
        return 'You are all set';
    }
};
