import { noop } from 'constants/functions';
import { validateLoginForm, validatePasswordForgotten, validateRegistrationForm } from 'pages/Sign/validation';
import { getUserCountryId } from 'utils/getUserCountryId';

// const initialValues: YEAY.UserCreateAccountRequest = {
//     username: '',
//     // countryCode: '',
//     // countryId: getUserCountryId() || 'us',
//     locale: getUserCountryId() || 'us',
//     mobileNumber: '',
//     email: '',
//     smsVerificationCode: '',
//     password: '',
//     userGender: undefined,
//     dateOfBirth: '',
//     language: ''
// };

export interface LoginFormValues {
    countryCode: string;
    phone: string;
    email: string;
    smsCode: string;
    password: string;
}
export const registrationInitialProps = {
    validateOnChange: true,
    // initialErrors: {
    //     username: '',
    //     countryCode: '',
    //     phone: '',
    //     email: '',
    //     smsCode: '',
    //     emailCode: '',
    //     password: ''
    // },
    // initialTouched: {
    //     username: true,
    //     countryCode: true,
    //     phone: true,
    //     email: true,
    //     smsCode: true,
    //     emailCode: true,
    //     password: true
    // },
    // initialValues: initialValues,
    initialValues: {
        username: '',
        countryCode: '',
        countryId: getUserCountryId() || 'us',
        phone: '',
        //email: '',
        smsVerificationCode: '',
        password: '',
        //userGender: undefined,
        //dateOfBirth: '',
        language: ''
    },
    validate: validateRegistrationForm,
    onSubmit: () => {}
};

export const loginInitialProps = {
    validateOnChange: true,
    initialValues: {
        countryCode: '',
        countryId: getUserCountryId() || 'us',
        phone: '',
        email: '',
        smsCode: '',
        password: ''
    },

    validate: validateLoginForm,
    onSubmit: noop
};

export const passwordForgottenInitialProps = {
    validateOnChange: true,
    // initialErrors: {
    //     email: ' ',
    //     phone: '',
    //     // mobileNumber: '',
    //     password: ' ',
    //     securityCode: ' '
    // },
    // initialTouched: {
    //     email: false,
    //     phone: true,
    //     // mobileNumber: true,
    //     password: true,
    //     securityCode: true
    // },
    initialValues: {
        email: '',
        phone: '',
        countryCode: '',
        countryId: getUserCountryId() || 'us',
        password: '',
        securityCode: '',
        smsCode: ''
    },
    validate: validatePasswordForgotten,
    onSubmit: () => {}
};
