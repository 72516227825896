import { requiredFieldMessage } from 'constants/messages';
import { FormikErrors, FormikValues } from 'formik';

export const emptyFieldErrorMessage = 'fill the field';

interface FormErrors {
    brand?: string;
    product?: string;
}

//
/*** Brand ***/
//
const validateBrandName = (name: string) => (name?.length ? '' : requiredFieldMessage);

//
/*** Product ***/
//
const validateProductName = (name: string) => (name?.length ? '' : requiredFieldMessage);

export const validateUploadForm = async ({ brand, product }: FormikValues) => {
    const errors: FormikErrors<FormErrors> = {};

    errors.brand = validateBrandName(brand);
    errors.product = validateProductName(product);

    // /!brand && (errors.brand = emptyFieldErrorMessage);
    // /!product && (errors.product = emptyFieldErrorMessage);

    return errors;
};
