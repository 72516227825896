import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

/**
 * Effect to load JWT token for anonymous user
 */
const loadToken = createEffect({
    handler: async () => {
        const { token } = await API.user.createAccountAnonymous({ locale: 'en_EN' });

        return token || '';
    }
});

const setUser = createEvent<YEAY.GetUserResponse>();
const user = createStore<YEAY.GetUserResponse>({ userId: '' }).on(setUser, (_, user: YEAY.GetUserResponse) => user);

const setToken = createEvent<string>();
const token = createStore('')
    .on(loadToken.doneData, (_, token) => token)
    .on(setToken, (_, token) => token);

const userEffects = { loadToken };
const userEvents = { setUser, setToken };
const userStores = { user, token };

export { userEffects, userStores, userEvents };
