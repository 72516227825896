import arrowIcon from 'assets/arrow_icon.svg';
import { ArrowImage, ItemSpan, ListItemWrapper, ListWrapper, Selector } from 'components/common/grid/Selector/styles';
import { Label } from 'components/common/Input/WrappedInput/styles';
import { Column } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { useField } from 'formik';
import { useCloseClick } from 'hooks/closeClick';
import { useModal } from 'hooks/modal';
import React, { FC, useEffect, useRef, useState } from 'react';

interface Props {
    values: string[];
    name: string;
    label?: string;
}

export const Select: FC<Props> = ({ values, name, label }) => {
    const { visible, close, open } = useModal();
    const [selectedItem, setSelectedItem] = useState(values[1]);
    const [, , { setValue }] = useField(name);
    const componentRef = useRef<HTMLDivElement>(null);

    const onSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { value } = event.currentTarget;
        setSelectedItem(value);
        setValue(value);
        close();
    };

    useCloseClick(componentRef, close);

    useEffect(() => {
        setValue(selectedItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Column ref={componentRef} width="100%">
            {label && <Label>{label}</Label>}

            <Selector visible={visible} onClick={visible ? close : open}>
                <MarginWrapper marginRight="20px">
                    <ItemSpan>{selectedItem}</ItemSpan>
                </MarginWrapper>

                <ArrowImage src={arrowIcon} visible={visible} />
            </Selector>

            <ListWrapper visible={visible}>
                {values.map(item => (
                    <ListItemWrapper key={item} active={item === selectedItem} value={item} onClick={onSelect}>
                        <ItemSpan>{item} </ItemSpan>
                    </ListItemWrapper>
                ))}
            </ListWrapper>
        </Column>
    );
};
