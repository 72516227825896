import { createEvent, createStore } from 'effector';
import { StrictTitle } from 'types/data';
import { StrictVisibility } from 'types/styles';

interface Modal extends StrictVisibility {}

const initializeStoreModal = () => {
    const openModal = createEvent();
    const closeModal = createEvent();

    const modal = createStore<Modal>({
        visible: false
    })
        .on(openModal, () => ({
            visible: true
        }))
        .on(closeModal, () => ({
            visible: false
        }));

    return { modal, openModal, closeModal };
};

export const loaderModal = initializeStoreModal();
export const successModal = initializeStoreModal();

export interface UploadProgressModalProps {
    visible?: boolean;
    uploaded?: boolean;
    progress?: number;
    poster?: string;
}

const initialUploadProgressModal: UploadProgressModalProps = {
    visible: false,
    uploaded: false,
    progress: 0,
    poster: ''
};

const openUploadProgressModal = createEvent();
const closeUploadProgressModal = createEvent();
const updateUploadProgressModal = createEvent<UploadProgressModalProps>();

const uploadProgressModal = createStore<UploadProgressModalProps>(initialUploadProgressModal)
    .on(openUploadProgressModal, () => ({ ...initialUploadProgressModal, visible: true }))
    .reset(closeUploadProgressModal)
    .on(updateUploadProgressModal, (state, newState) => ({ ...state, ...newState }));

export interface AsyncModal extends StrictTitle {
    visible?: boolean;
    successText?: string;
    errorText?: string;
    onOk?: () => void;
    closeText?: string;
    okText?: string;
}

const initialAsyncModal: AsyncModal = {
    visible: false,
    title: '',
    successText: '',
    errorText: '',
    closeText: '',
    okText: 'Okay'
};

const openAsyncModal = createEvent<AsyncModal>();
const closeAsyncModal = createEvent();

const asyncModal = createStore<AsyncModal>(initialAsyncModal)
    .on(openAsyncModal, (state, newState) => ({ ...state, ...newState, visible: true }))
    .on(closeAsyncModal, () => initialAsyncModal);

const modalEvents = {
    openAsyncModal,
    closeAsyncModal,
    openUploadProgressModal,
    closeUploadProgressModal,
    updateUploadProgressModal
};
const modalStores = {
    asyncModal,
    uploadProgressModal
};

export { modalStores, modalEvents };
