import { useStore } from 'effector-react';
import { ReactElement } from 'react';
import { Steps, stepsStores } from 'stores/steps';

interface Props {
    step: Steps;
    children: ReactElement;
}

export const StepRoute = ({ step, children }: Props) => {
    const currentStep = useStore(stepsStores.step);
    return step === currentStep ? children : null;
};
