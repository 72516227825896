import yeayCheckmark from 'assets/icons/password_input_checkmark.svg';
import { CustomImg } from 'components/common/CustomImg';
import { InputProps } from 'components/common/Input';
import { CheckListArray, checkMarkIconDiameter } from 'components/common/Input/PasswordInput/constants';
import { Span } from 'components/common/typography/Span';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { noop } from 'constants/functions';
import { grey7 } from 'constants/styles/colors';
import React from 'react';
import { StyledInput } from '../StyledInput';

export interface Props extends InputProps {
    name: string;
    checkListArray: CheckListArray;
    error?: string;
    touched: boolean;
}

export const checkPasswordValidity = (value: string, booleanArray: RegExp[]) =>
    booleanArray.map(item => !!value.match(item));

export const PasswordInput = ({
    error,
    touched,
    checkListArray,
    onChange = noop,
    onBlur = noop,
    name,
    value = ''
}: Props) => {
    // const [inputValue, setInputValue] = useState(defaultValue);
    // const [isFocused, setIsFocused] = useState(false);
    // const [isShown, setIsShown] = useToggle();

    const booleanArray = checkPasswordValidity(
        value,
        checkListArray.map(({ regExp }) => regExp)
    );

    // const isValid = !booleanArray.includes(false);
    // const onInputFocus = () => {
    //     setIsFocused(true);
    // };
    // const onInputBlur = () => {
    //     setIsFocused(false);
    // };
    // const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     setInputValue(e.currentTarget.value);
    //     onChange(e);
    // };
    // useEffect(() => setInputValue(defaultValue), [defaultValue]);

    return (
        <Column width="100%">
            <Section marginBottom="11px">
                {/*<RelativeWrapper height="55px">*/}
                {/*    <ToggleVisibilityButton color={inputValue ? black : undefined} onClick={setIsShown}>*/}
                {/*        {isShown ? 'Hide' : 'Show'}*/}
                {/*    </ToggleVisibilityButton>*/}
                {/*    <Label borderColor={isValid ? green : isFocused ? black : grey5}>*/}
                {/*        <TextInput*/}
                {/*            defaultValue={defaultValue}*/}
                {/*            name={name}*/}
                {/*            placeholder="Password"*/}
                {/*            type={isShown ? 'text' : 'password'}*/}
                {/*            onBlur={onInputBlur}*/}
                {/*            onChange={onInputChange}*/}
                {/*            onFocus={onInputFocus}*/}
                {/*        />*/}
                {/*    </Label>*/}
                {/*    {error && <ErrorSpan error={error} />}*/}
                {/*</RelativeWrapper>*/}
                <StyledInput
                    hideError
                    error={error}
                    name={name}
                    touched={touched}
                    type="password"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    // {...field}
                />
            </Section>
            {!!checkListArray?.length &&
                checkListArray.map(({ text }, index) => (
                    <Section key={text} alignCenter marginBottom="8px" marginLeft="10px">
                        {booleanArray[index] ? (
                            <CustomImg
                                alt="Checkmark"
                                height={checkMarkIconDiameter}
                                src={yeayCheckmark}
                                width={checkMarkIconDiameter}
                            />
                        ) : (
                            <ContentWrapper height={checkMarkIconDiameter} width={checkMarkIconDiameter}>
                                <Span color={grey7} fontSize="20px" lineHeight="18px">
                                    &bull;
                                </Span>
                            </ContentWrapper>
                        )}
                        <Span fontSize="10px" fontWeight="600" lineHeight="18px">
                            &nbsp;&nbsp;{text}
                        </Span>
                    </Section>
                ))}
        </Column>
    );
};
