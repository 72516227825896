import { createEvent, createStore } from 'effector';

export enum Steps {
    Entry,
    VideoRecord,
    Welcome,
    Registration,
    Login,
    PasswordForgotten,
    Confirmation,
    Upload,
    ThankYou
}

// Current step
const setStep = createEvent<Steps>();

const step = createStore(process.env.NODE_ENV === 'production' ? Steps.Entry : Steps.Entry).on(
    setStep,
    (_steps, newStep) => newStep
);

// Steps history
const goToPrevStep = createEvent();

const stepsHistory = createStore([step.getState()])
    .on(goToPrevStep, steps => {
        if (steps.length > 1) {
            const prevStep = steps[steps.length - 2];
            // setStep below doesn't affect
            // "steps" variable in this function
            setStep(prevStep);

            return steps.slice(0, -1);
        }

        return steps;
    })
    .on(setStep, (steps, newStep) => [...steps, newStep]);

export const stepsStores = { step, stepsHistory };
export const stepsEvents = { setStep, goToPrevStep };
