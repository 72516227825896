import styled from 'styled-components/macro';

export const FallbackStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: black;

    color: white;
    text-align: center;
`;
