import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { SimpleButton } from 'components/common/buttons/SimpleButton';
import { InputSelector } from 'components/common/grid/InputSelector';
import { inputTypes, LoginInputType } from 'components/common/grid/InputSelector/constants';
import { EmailField } from 'components/Formik/EmailField';
import { PasswordField } from 'components/Formik/PasswordField';
import { PhoneField } from 'components/Formik/PhoneField';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { useStore } from 'effector-react';
import { Form, useField, useFormikContext } from 'formik';
import { LoginFormValues } from 'pages/Sign/formikInitialProps';
import { LoginEntityHeight, LoginEntityMarginBottom } from 'pages/Sign/Login/Steps/EmailOrPhone/constants';
import React, { FC, FormEvent, useState } from 'react';
import { signEffects, signEvents } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { SignStepProps } from 'types/sign';

const { authenticateUser, checkUsernameExistence } = signEffects;

export const EmailOrPhoneStep: FC<SignStepProps> = () => {
    // const chosenInput = useStore(signStores.chosenSignInput);
    const isCheckPending = useStore(checkUsernameExistence.pending);
    const isAuthPending = useStore(authenticateUser.pending);

    const [chosenInput, setChosenInput] = useState(inputTypes[0]);
    const [, { error: chosenInputError }] = useField(chosenInput.toLowerCase());
    const [, { error: passwordError }, helpers] = useField('password');
    const { values } = useFormikContext<LoginFormValues>();

    const error = !!chosenInputError || !!passwordError;

    const loginFormSubtitle =
        chosenInput === 'Phone'
            ? 'Please enter your phone number and password.'
            : 'Please enter your email or username and password.';

    // const choosePhone = () => {
    //     signEffects.changeChosenSignInput('phone');
    // };
    const chooseEmail = () => {
        signEvents.changeChosenSignInput('Email');
    };

    const setErrorPassword = (error: string) => helpers.setError(error);

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (error) {
            return;
        }

        signEffects.authenticateUser({
            usernameOrEmail: values.email,
            mobileNumber: values.countryCode + values.phone,
            password: values.password,
            errorForPasswordInput: setErrorPassword
        });
    };

    const onChangeLoginType = (item: LoginInputType) => {
        setChosenInput(item);
    };

    const goToForgotPasswordPage = () => {
        stepsEvents.setStep(Steps.PasswordForgotten);
    };

    // useEffect(() => {
    //     isAuthPending ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isAuthPending]);

    return (
        <Form onSubmit={handleFormSubmit}>
            <MarginWrapper marginBottom="44px">
                <TitleBlock subtitle={loginFormSubtitle} title="Log In" />
            </MarginWrapper>
            <Section justifyCenter>
                <InputSelector
                    activeItem={chosenInput}
                    inputs={[
                        {
                            type: 'Phone',
                            input: (
                                <Column width="100%">
                                    <Section height={LoginEntityHeight} marginBottom={LoginEntityMarginBottom}>
                                        <PhoneField />
                                    </Section>
                                    <Section>
                                        <PasswordField login />
                                    </Section>
                                </Column>
                            ),
                            title: 'Phone'
                        },
                        {
                            type: 'Email',
                            input: (
                                <Column width="100%">
                                    <Section height={LoginEntityHeight} marginBottom={LoginEntityMarginBottom}>
                                        <EmailField login isLoading={isCheckPending} onFocus={chooseEmail} />
                                    </Section>
                                    <Section>
                                        <PasswordField login />
                                    </Section>
                                </Column>
                            ),
                            title: 'Email/Username'
                        }
                    ]}
                    onChange={onChangeLoginType}
                />
            </Section>
            <Section justifyEnd /*marginTop="21px"*/>
                <SimpleButton type="button" onClick={goToForgotPasswordPage}>
                    Forgot Password?
                </SimpleButton>
            </Section>
            <Section justifyCenter marginTop="13px">
                <Button disabled={error} height="48px" isLoading={isAuthPending} type="submit">
                    Log in
                </Button>
            </Section>
        </Form>
    );
};
