import confetti from 'assets/background_confetti.svg';
import { Button } from 'components/common/buttons/Button';
import { Span } from 'components/common/typography/Span';
import { grey3 } from 'constants/styles/colors';
import styled, { css } from 'styled-components';
import { Sizes } from 'types/styles';

export interface TitleWrapperProps {
    withBackgroundImage?: boolean;
}

export const TitleWrapper = styled.div<TitleWrapperProps>`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;

    ${({ withBackgroundImage }) =>
        withBackgroundImage &&
        css`
            background: url(${confetti}) no-repeat;
            height: 139px;
            width: 166px;
            padding-bottom: 20px;
        `}
`;

export const Modal = styled.div<Sizes>`
    /*height: ${({ height }) => height || '330px'};*/
    width: ${({ width }) => width || '290px'};
    box-sizing: border-box;
    padding: 30px;
    background-color: white;
    border-radius: 20px;
`;

export const ModalButton = styled(Button)`
    height: 52px;
    width: 200px;
    font-size: 14px;
    font-weight: 600;
`;

export const TitleSpan = styled(Span)`
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 19px;
`;

export const MessageSpan = styled(Span)`
    text-align: center;
    color: ${grey3};
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
`;
