import { PhoneInput } from 'components/common/Input/PhoneInput';
import { InputInformationSpan } from 'components/common/Input/StyledInput/styles';
import { Column } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { useField } from 'formik';
import React from 'react';

interface Props {
    name?: string;
    borderColor?: string;
}

export const PhoneField = ({ name = 'phone', borderColor }: Props) => {
    const [{ value, ...field }, { touched, error }] = useField(name);

    return (
        <Column alignCenter width="100%">
            <PhoneInput borderColor={borderColor} value={value} {...field} />
            <MarginWrapper marginTop="8px">
                <InputInformationSpan error={error} touched={touched}>
                    {touched && error}
                </InputInformationSpan>
            </MarginWrapper>
        </Column>
    );
};
