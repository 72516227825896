import { Layout } from 'components/Layout';
import { AsyncModal } from 'components/modals/AsyncModal';
import { Notifications } from 'components/Notifications';
import { StepRoute } from 'components/StepRoute';
import { GlobalStyle } from 'constants/styles/global';
import 'fonts.css';
import { EntryPage } from 'pages/Entry';
import { ConfirmationPage } from 'pages/Sign/Confirmation';
import { LoginPage } from 'pages/Sign/Login';
import { PasswordForgottenPage } from 'pages/Sign/PasswordForgotten';
import { RegistrationPage } from 'pages/Sign/Registration';
import { WelcomeUserPage } from 'pages/Sign/Welcome';
import { ThankYouPage } from 'pages/ThankYou';
import { UploadPage } from 'pages/Upload';
import { VideoRecordPage } from 'pages/VideoRecord';
import React, { useEffect } from 'react';
import { productEvents } from 'stores/product';
import { Steps } from 'stores/steps';
import { YEWRInitParameters } from 'types/plugin';
import { UploadProgressModal } from './components/modals/UploadProgressModal';
import { appVersion, womDevBaseURL, womProdBaseURL, yeayDevBaseURL, yeayProdBaseURL } from './constants/services';
import { globalEvents } from './stores/global';

const { setWOMBaseURL, setYEAYBaseURL } = globalEvents;
const {
    setInitialRemoteProductValues,
    setRemoteProductValues,
    setOrganizationPublicId,
    setFacilitatorId
} = productEvents;

const App = ({
    facilitatorId,
    organizationPublicId,
    remoteProduct,
    isDev,
    ...homeStylesProps
}: Omit<YEWRInitParameters, 'selector'>) => {
    const product = remoteProduct?.name || '';

    useEffect(() => {
        setWOMBaseURL(isDev ? womDevBaseURL : womProdBaseURL);
        setYEAYBaseURL(isDev ? yeayDevBaseURL : yeayProdBaseURL);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        remoteProduct && setInitialRemoteProductValues(remoteProduct);
        remoteProduct && setRemoteProductValues(remoteProduct);
        organizationPublicId && setOrganizationPublicId(organizationPublicId);
        facilitatorId && setFacilitatorId(facilitatorId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GlobalStyle />
            <Layout className={`web-recorder-plugin  ${appVersion} ${isDev ? 'DEV' : ''}`} id={product}>
                <UploadProgressModal />
                <AsyncModal />
                <Notifications />
                {/* <LoaderModal /> */}

                <StepRoute step={Steps.Entry}>
                    <EntryPage {...homeStylesProps} />
                </StepRoute>
                <StepRoute step={Steps.VideoRecord}>
                    <VideoRecordPage />
                </StepRoute>

                <StepRoute step={Steps.Welcome}>
                    <WelcomeUserPage />
                </StepRoute>
                <StepRoute step={Steps.Registration}>
                    <RegistrationPage />
                </StepRoute>
                <StepRoute step={Steps.Login}>
                    <LoginPage />
                </StepRoute>
                <StepRoute step={Steps.PasswordForgotten}>
                    <PasswordForgottenPage />
                </StepRoute>
                <StepRoute step={Steps.Confirmation}>
                    <ConfirmationPage />
                </StepRoute>
                <StepRoute step={Steps.Upload}>
                    <UploadPage />
                </StepRoute>
                <StepRoute step={Steps.ThankYou}>
                    <ThankYouPage />
                </StepRoute>
            </Layout>
        </>
    );
};

export default App;
