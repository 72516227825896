import * as locale from 'locale-codes';

// creates array given length from string
export const formatInput = (value: string, codeLength: number) =>
    Array.from({ length: codeLength }, (_, index) => value[index] || '');

//converts passed date or todayDate if no passed value in miliseconds

export const getDateInMiliseconds = (value = new Date()) => value.getTime();

export const getDateBeforeAndReturn = (beforeYears = 1) => {
    const today = new Date();

    return new Date(today.getFullYear() - beforeYears, today.getMonth(), today.getDate());
};

export const getLocaleTag = (langCode: string) => locale.getByTag(langCode).tag || '';

export const getLocaleStandard = (langCode: string) => locale.getByTag(langCode).name || ''; // 'Portuguese';

export const findElementInChildrenList = (targetElement: Element, searchElement: EventTarget | null) => {
    let isInParentBlock = false;
    const checkChildrenRef = (el: Element) => {
        if (el.childElementCount === 0) return;
        else
            el.childNodes.forEach((el: any) => {
                if (searchElement === el) isInParentBlock = true;
                checkChildrenRef(el);
            });
    };

    checkChildrenRef(targetElement);
    return isInParentBlock;
};
