import { LoginInputType } from 'components/common/grid/InputSelector/constants';
import { userStorageName } from 'constants/storage';
import { createEffect, createEvent, createStore, restore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { API } from 'services';
import { Steps, stepsEvents } from 'stores/steps';
import { userEvents } from 'stores/user';
import { createNotifyingEffect } from 'utils/common';

const { setToken, setUser } = userEvents;
const { setStep } = stepsEvents;

//
/*** Username ***/
//
const checkUsernameExistence = createNotifyingEffect({
    handler: async (username: string) => {
        const { exists } = await API.sign.checkUsernameExistence({ username });

        return exists;
    }
});

//
/*** Chosen Registration Input ***/
//
const changeChosenSignInput = createEvent<LoginInputType>();
const chosenSignInput = createStore<LoginInputType>('Phone').on(changeChosenSignInput, (_, chosenInput) => chosenInput);

//
/*** Mobile Number ***/
//
const analyzeMobileNumber = createNotifyingEffect({
    handler: async (mobileNumber: string) => {
        const { result } = await API.sign.analyzeMobileNumber({ mobileNumber });

        return result;
    }
});

//
/*** Email ***/
//
const analyzeEmail = createEffect({
    handler: async (email: string) => {
        const { result } = await API.sign.analyzeEmail({ email }).catch(err => err);

        return result;
    }
});

//
/*** Validation Email ***/
//
const sendValidationLinkEmail = createEffect({
    handler: async (email: string) => await API.sign.sendValidationLinkEmail({ email }).catch(err => err)
});

//
/*** Sms Code ***/
//
const sendSmsCode = createNotifyingEffect({
    handler: async (mobileNumber: string) => {
        await API.sign.sendVerificationSms({ mobileNumber });
    }
});

const checkSmsCode = createEffect({
    handler: async (data: YEAY.CheckSmsCodeRequest) => {
        try {
            const { result } = await API.sign.checkSmsCode(data);

            return result;
        } catch (e) {
            return e.message;
        }
    }
});

//
/*** Forgotten Password via SMS ***/
//

const sendForgottenPasswordSmsCode = createNotifyingEffect({
    handler: async (mobileNumber: string) => {
        await API.sign.sendSmsForgottenPassword({ mobileNumber });
    }
});

const setNewPasswordViaSms = createEffect({
    handler: async (data: YEAY.ChangeUserPasswordViaSmsRequest) =>
        await API.sign.setNewPasswordViaSms(data).catch(err => err)
});

//
/*** Forgotten Password via email***/
//
const sendForgottenPasswordEmail = createEffect({
    handler: async (email: string) => await API.sign.sendForgottenPasswordEmail({ email }).catch(err => err)
});
const setNewPasswordViaEmail = createEffect({
    handler: async (data: YEAY.AuthenticateWithTokenRequest) =>
        await API.sign.setNewPasswordViaEmail(data).catch(err => err)
});

//
/*** Auth ***/
//
const setIsSubmitButtonShowed = createEvent<boolean>();
const isSubmitButtonShowed = restore(setIsSubmitButtonShowed, false);

//
/*** Authorization Without Account ***/
//
const authorizeWithoutAccount = createNotifyingEffect({
    handler: async () => {
        const response = await API.sign.authenticateUserWithoutAccount({});

        if (response.token) {
            setToken(response.token);
            setStep(Steps.Upload);
        }
        return response;
    }
});

//
/*** Wallet ***/
//

const getWalletByToken = createEffect({
    handler: async () => {
        try {
            return await API.womServices.wallet.getWallet({});
        } catch {
            return {};
        }
    }
});

const wallet = createStore<WOM.WalletResponse>({}).on(getWalletByToken.doneData, (_, wallet) => wallet);

export enum TypeOfUser {
    Undefined,
    withoutAuthorization,
    withoutWallet,
    hasFreeStakes,
    withoutFreeStakesAndPositiveWomBalance,
    withoutFreeStakesAndZeroWomBalance
}

//
/*** Logic for set Type of User ***/
/*** If user choose "Continue without account" - type of user = withoutAuthorization ***/
/*** If user logged by username or mobile-phone: ***/
/***    if hasWallet from Wallet of user is true: ***/
/***        if freeStakesRemaining value of user not equal 0: ***/
/***            type of user = hasFreeStakes. ***/
/***        if balance of Wallet not equal 0: ***/
/***            type of user = withoutFreeStakesAndPositiveWomBalance. ***/
/***        if balance of Wallet not equal 0: ***/
/***            type of user = withoutFreeStakesAndZeroWomBalance. ***/
/***    if hasWallet from Wallet of user is false: ***/
/***        type of user = withoutWallet. ***/
/*** If user choose "Continue without account": ***/
/***    type of user = withoutAuthorization. ***/
//

const getTypeOfUserByUserProfile = createEffect({
    handler: async (user: YEAY.UserJwtTokenResponse) => {
        await getWalletByToken();
        const walletData = wallet.getState();
        const { hasWallet } = walletData;

        if (hasWallet) {
            if (user.user?.freeStakesRemaining) {
                return TypeOfUser.hasFreeStakes;
            } else {
                const balance = walletData.items?.[0]?.balance || 0;
                if (balance > 0) {
                    return TypeOfUser.withoutFreeStakesAndPositiveWomBalance;
                } else {
                    return TypeOfUser.withoutFreeStakesAndZeroWomBalance;
                }
            }
        } else {
            return TypeOfUser.withoutWallet;
        }
    }
});

const typeOfUser = createStore<TypeOfUser>(TypeOfUser.Undefined)
    .on(authorizeWithoutAccount.doneData, () => TypeOfUser.withoutAuthorization)
    .on(getTypeOfUserByUserProfile.doneData, (_, newTypeOfUser) => newTypeOfUser);

// typeOfUser.watch(state => console.log('typeOfUser', state));

interface AuthenticateUserData extends YEAY.UserAuthChallengeEmailOrUsernameOrPhoneRequest {
    errorForPasswordInput?: (errorMessage: string) => void;
}

const authenticateUser = createNotifyingEffect({
    handler: async (data: AuthenticateUserData) => {
        try {
            const response = await API.sign.authenticateUser(data).then(err => err);

            const { token, user } = response;

            token && setToken(token);

            if (user) {
                setUser(user);

                if (user.isAccountVerified && (user.isEmailValidated || !!user.profile?.mobileNumber)) {
                    setStep(Steps.Upload);
                } else if (!!user.email && !user.isEmailValidated) {
                    setStep(Steps.Confirmation);
                }
            }
            return response;
        } catch (e) {
            const { message }: YEAY.Error404NotFoundResponse = e;
            if (data.errorForPasswordInput) data.errorForPasswordInput(message || 'Oops, Login failed');
        }
    }
});
const userStorage = connectLocalStorage(userStorageName).onError(err => console.log(err));
const user = createStore<YEAY.UserJwtTokenResponse>(userStorage.init({})).on(
    authenticateUser.doneData,
    (_, newState) => newState
);
user.watch(userStorage);
user.updates.watch(state => setIsSubmitButtonShowed(!!state?.user?.freeStakesRemaining));
user.updates.watch(state => getTypeOfUserByUserProfile(state));

// user.watch(state => console.log('user', state));

//
/*** Creating Account ***/
//
const createAccount = createNotifyingEffect({
    handler: async (data: YEAY.UserCreateAccountRequest) => await API.sign.createAccount(data)
});

//
/*** Creating WOM Wallet ***/
//
// const createWOMWallet = createNotifyingEffect({
//     handler: async () => await API.wom.createWOMWallet()
// });

//
/*** Get Current Authorization ***/
//
const getCurrentAuthorization = createNotifyingEffect({
    handler: async (userId: string) => await API.sign.getCurrentAuthorization({ userId })
});

//
/*** Exports ***/
//
export const signEffects = {
    checkUsernameExistence,
    analyzeMobileNumber,
    analyzeEmail,
    sendSmsCode,
    checkSmsCode,
    sendForgottenPasswordSmsCode,
    setNewPasswordViaSms,
    sendForgottenPasswordEmail,
    setNewPasswordViaEmail,
    authenticateUser,
    createAccount,
    // createWOMWallet,
    getCurrentAuthorization,
    sendValidationLinkEmail,
    authorizeWithoutAccount
};

export const signEvents = { changeChosenSignInput };

export const signStores = { chosenSignInput, user, isSubmitButtonShowed, typeOfUser };
