import { StyledInput } from 'components/common/Input/StyledInput';
import { useField } from 'formik';
import React, { FC } from 'react';

interface Props {
    onFocus?(): void;
    login?: boolean;
    isLoading?: boolean;
    borderColor?: string;
}

export const UsernameField: FC<Props> = ({ /* onFocus,*/ login, isLoading, borderColor }) => {
    const [{ value, ...field }, { touched, error }] = useField('username');

    const validText = !error && touched && !login ? 'Great, that username is available' : undefined;

    return (
        <StyledInput
            {...field}
            borderColor={borderColor}
            error={error}
            isLoading={isLoading}
            // isError={!!error}
            // isValid={isValid}
            placeholder="username"
            touched={touched}
            type="text"
            validText={validText}
            value={value}
        />
    );
};
