import { createEvent, restore } from 'effector';
import { womDevBaseURL, yeayDevBaseURL } from '../constants/services';

const setYEAYBaseURL = createEvent<string>();
const yeayBaseURL = restore(setYEAYBaseURL, yeayDevBaseURL);

const setWOMBaseURL = createEvent<string>();
const womBaseURL = restore(setWOMBaseURL, womDevBaseURL);

const setApplicationProperties = createEvent<[number, number]>();
const applicationProperties = restore<[number, number]>(setApplicationProperties, [0, 0]);

export const globalStores = { yeayBaseURL, womBaseURL, applicationProperties };
export const globalEvents = { setYEAYBaseURL, setWOMBaseURL, setApplicationProperties };
