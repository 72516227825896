import App from 'App';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { YEWRInitParameters } from 'types/plugin';

const rootElement = document.getElementById('yeay-plugin');

// if (process.env.NODE_ENV === 'production') {

window.yeay = {
    init: (params: YEWRInitParameters) => {
        const { selector, isDev, ...appParams } = params;
        const renderElement = selector ? document.querySelector(selector) : rootElement;

        if (renderElement) {
            ReactDOM.render(
                <StrictMode>
                    <App isDev={isDev} {...appParams} />
                </StrictMode>,
                renderElement
            );
        }
    }
};

// } else {
//     // Mock data
//     const remoteProduct: WOM.RemoteProductDescription = {
//          name: 'Iphone 13 (YEAY)',
//          brand: 'Apple',
//         publicId: 'WOMFORBULLZ123456789',
//         url:
//             'https://aliexpress.ru/item/4000622830703.html?algo_pvid=eaf5f4f8-f1d0-4b1c-877e-1e5326ba96be&algo_expid=eaf5f4f8-f1d0-4b1c-877e-1e5326ba96be-0&btsid=0b8b035616146836185221432e886e&ws_ab_test=searchweb0_0,searchweb201602_,searchweb201603_',
//         imageUrl: 'https://items.s1.citilink.ru/1192425_v01_b.jpg'
//     };
//     ReactDOM.render(
//         <StrictMode>
//             <App
//                 isDev
//                 facilitatorId="INCODEWETRUST1_60950f88936aed152f526fc2"
//                 organizationPublicId="INCODEWETRUST1_60950f88936aed152f526fc2"
//                 remoteProduct={remoteProduct}
//             />
//         </StrictMode>,
//         rootElement
//     );
// }

let event = new Event('yeay-recorder-init', { bubbles: true });
document.body.dispatchEvent(event);
