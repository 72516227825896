import { StyledInput } from 'components/common/Input/StyledInput';
import { useField } from 'formik';
import React, { FC } from 'react';

interface Props {
    onFocus?(): void;
    login?: boolean;
    isLoading?: boolean;
    // withLeftHelper?: boolean;
    borderColor?: string;
}

export const EmailField: FC<Props> = ({ /*onFocus,*/ login, isLoading, borderColor }) => {
    const [field, { touched, error }] = useField('email');
    const placeholder = login ? 'Enter email address or username' : 'Email';

    return (
        <StyledInput
            borderColor={borderColor}
            error={error}
            isLoading={isLoading}
            placeholder={placeholder}
            touched={touched}
            {...field}
        />
    );
};
