import { white } from 'constants/styles/colors';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    height: 100%;
    padding: 55px 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: ${white};
`;

export const Text = styled.p`
    font-size: 18px;
    max-width: 220px;
    margin: 0 auto;
`;

export const Email = styled.p`
    font-size: 24px;
    margin: 45px 0;
    font-weight: 800;
    word-break: break-all;
`;

export const ButtonsWrapper = styled.div`
    width: 100%;
    max-width: 231px;
    margin-top: 70px;
    display: grid;
    grid-gap: 15px;
`;
