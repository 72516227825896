import axios from 'services/axios';
import { yeayBaseURL } from '../constants/stores';

export const checkUsernameExistence = (data: YEAY.UsernameExistsRequest) =>
    axios<YEAY.ExistenceResponse>({
        url: '/user/username-exists',
        baseURL: yeayBaseURL(),
        data
    });

export const analyzeMobileNumber = (data: YEAY.AnalyzeMobileNumberRequest) =>
    axios<YEAY.AnalyzeMobileNumberResponse>({
        url: '/user/sms-analyze-mobile-number',
        baseURL: yeayBaseURL(),
        data
    });

//TODO: [any]
export const analyzeEmail = (data: { email: string }) =>
    axios<any>({
        url: '/user/analyze-email',
        baseURL: yeayBaseURL(),
        data
    });

export const sendVerificationSms = async (data: YEAY.SendVerificationSmsRequest) =>
    axios<YEAY.SendVerificationSmsResponse>({
        url: '/user/sms-send-verification',
        baseURL: yeayBaseURL(),
        data
    });

export const checkSmsCode = (data: YEAY.CheckSmsCodeRequest) =>
    axios<YEAY.CheckSmsCodeResponse>({
        url: '/user/sms-check-verification-code',
        baseURL: yeayBaseURL(),
        data
    });

export const createAccount = (data: YEAY.UserCreateAccountRequest) =>
    axios<YEAY.UserJwtTokenResponse>({
        url: '/user/create-account',
        baseURL: yeayBaseURL(),
        data
    });

export const authenticateUser = (data: YEAY.UserAuthChallengeEmailOrUsernameOrPhoneRequest) =>
    axios<YEAY.UserJwtTokenResponse>({
        url: '/user/authenticate',
        baseURL: yeayBaseURL(),
        data
    });

export const authenticateUserWithoutAccount = (data: YEAY.AuthenticateWebRecorderRequest) =>
    axios<YEAY.UserJwtTokenResponse>({
        url: '/user/authenticate-webrecorder',
        baseURL: yeayBaseURL(),
        data
    });

export const sendForgottenPasswordEmail = (data: YEAY.UserWantsForgottenPasswordRequest) =>
    axios<YEAY.MessageResponseBase>({
        url: '/user/send-forgotten-password-email',
        baseURL: yeayBaseURL(),
        data
    });

export const setNewPasswordViaEmail = (data: YEAY.AuthenticateWithTokenRequest) =>
    axios<YEAY.MessageResponseBase>({
        url: '/user/authenticate-with-token',
        baseURL: yeayBaseURL(),
        data
    });

export const sendSmsForgottenPassword = (data: YEAY.SendForgottenPasswordSmsRequest) =>
    axios<YEAY.SendForgottenPasswordSmsResponse>({
        url: '/user/sms-send-forgotten-password',
        baseURL: yeayBaseURL(),
        data
    });

export const setNewPasswordViaSms = (data: YEAY.ChangeUserPasswordViaSmsRequest) =>
    axios<YEAY.ChangeUserPasswordViaSmsResponse>({
        url: '/user/sms-change-user-password',
        baseURL: yeayBaseURL(),
        data
    });

export const getCurrentAuthorization = (data: YEAY.GetCurrentAuthorizationsRequest) =>
    axios<YEAY.GetCurrentAuthorizationsResponse>({
        url: '/user/get-current-authorization',
        baseURL: yeayBaseURL(),
        data
    });

export const sendValidationLinkEmail = (data: YEAY.UserWantsValidationLinkEmailRequest) =>
    axios<YEAY.MessageResponseBase>({
        url: '/user/send-validation-link-email',
        baseURL: yeayBaseURL(),
        data
    });
