import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { LinkButton } from 'components/common/buttons/LinkButton';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { dark, white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { useField } from 'formik';
import React, { FC } from 'react';
import { modalEvents } from 'stores/initialize/initialize.modal.store';
import { signEffects } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { userStores } from 'stores/user';
import { SignStepProps } from 'types/sign';

const { setStep } = stepsEvents;

const { getCurrentAuthorization } = signEffects;

export const ConfirmationStep: FC<SignStepProps> = ({ setCurrentStep }) => {
    const isAuthCheckLoading = useStore(getCurrentAuthorization.pending);
    // const isWOMCreationLoading = useStore(createWOMWallet.pending);
    // const isConfirmationLoading = isAuthCheckLoading || isWOMCreationLoading;
    const isConfirmationLoading = isAuthCheckLoading;
    // const isResendingLoading = false;

    const [, { value: emailValue, error } /*, { setError }*/] = useField('email');
    const { userId /*, email */ } = useStore(userStores.user);

    const onResendClick = () => {};

    const onRegisterClick = () => {
        //setStep(Steps.Registration);
        setCurrentStep('EmailOrPhoneStep');
    };

    const onConfirmClick = async () => {
        const authResponse = await getCurrentAuthorization(userId);

        if (authResponse && authResponse.isAccountVerified && authResponse.isEmailValidated) {
            // const walletResponse = await createWOMWallet(true); // at least 1 argument needed

            // if (walletResponse) {
            //     setStep(Steps.Upload);
            // }
            setStep(Steps.Upload);
            setCurrentStep('EmailOrPhoneStep');
        } else if (authResponse) {
            modalEvents.openAsyncModal({
                title: 'Sorry, not confirmed yet!',
                errorText: `Please check your email inbox: ${emailValue}`
            });
        }
    };

    // useEffect(() => {
    //     isConfirmationLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isConfirmationLoading]);

    return (
        <>
            {/* <LoaderModal /> */}
            <Column alignCenter>
                <TitleBlock
                    // secure={emailValue}
                    subtitle="Please confirm your email before continuing"
                    title={`We have sent a confirmation email to ${emailValue}`}
                />
                <Section justifyCenter margin="70px 0px 25px">
                    <Button disabled={!!error} /*isLoading={isResendingLoading}*/ onClick={onResendClick}>
                        Resend
                    </Button>
                </Section>
                <Section justifyCenter marginBottom="25px">
                    <LinkButton fontSize="14px" fontWeight="600" lineHeight="16px" onClick={onRegisterClick}>
                        Reregister
                    </LinkButton>
                </Section>
                <Button
                    backgroundColor={dark}
                    color={white}
                    disabled={!!error}
                    isLoading={isConfirmationLoading}
                    onClick={onConfirmClick}
                >
                    I already confirmed
                </Button>
            </Column>
        </>
    );
};
