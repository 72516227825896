import defaultBackground from 'assets/thank_you_page_background.svg';
import { black } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

interface Props {
    background?: string;
}

export const Wrapper = styled.div`
    position: relative;
    ${flexCenter}
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: ${black};
`;

export const PageBackground = styled.section<Props>`
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background: ${black} url(${({ background }) => background || defaultBackground}) center no-repeat;
    background-size: cover;
    opacity: 0.5;
`;
