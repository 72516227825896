import React from 'react';
import { SecureSubTitle, StepSubTitle, StepTitle } from './styles';
import { Column, Row } from 'components/wrappers/FlexWrapper';
import { secureData } from 'utils/common';

export interface TitleBlockProps {
    title: string;
    subtitle?: string;
    secure?: string;
}

export const TitleBlock = ({ title, subtitle, secure }: TitleBlockProps) => (
    <Column alignCenter width="100%">
        <StepTitle>{title}</StepTitle>
        {subtitle && (
            <Row alignCenter justifyCenter marginTop="8px" width="80%">
                <StepSubTitle>
                    {subtitle} {secure && <SecureSubTitle>{secureData(secure)}</SecureSubTitle>}
                </StepSubTitle>
            </Row>
        )}
    </Column>
);
