/**
 * Get current frame image from video
 */
export const getFrame = (video: HTMLVideoElement) => {
    const canvas = document.createElement('canvas');
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;

    const ctx = canvas.getContext('2d');

    if (!ctx) return '';

    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
};

/**
 * Get duration from video
 */
export const getBlobDuration = async (blob: Blob | string) => {
    const tempVideoEl = document.createElement('video');

    const durationP = new Promise<{ video: HTMLVideoElement; duration: number }>(resolve =>
        tempVideoEl.addEventListener('loadedmetadata', () => {
            // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
            if (tempVideoEl.duration === Infinity) {
                tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
                tempVideoEl.ontimeupdate = () => {
                    tempVideoEl.ontimeupdate = null;
                    resolve({
                        video: tempVideoEl,
                        duration: tempVideoEl.duration
                    });
                    tempVideoEl.currentTime = 0;
                };
            }
            // Normal behavior
            else
                resolve({
                    video: tempVideoEl,
                    duration: tempVideoEl.duration
                });
        })
    );

    tempVideoEl.src = typeof blob === 'string' ? blob : window.URL.createObjectURL(blob);

    return durationP;
};

/**
 * Get video screen from startTime
 */
export const getVideoScreen = async (blob: Blob | string, startTime = 0) => {
    const { video } = await getBlobDuration(blob);

    video.currentTime = startTime;

    return new Promise<string>(res => {
        video.ontimeupdate = () => {
            const frame = getFrame(video);

            res(frame);
        };
    });
};

/**
 * Get date in special format
 */
export const getRecordedDate = () => {
    const date = new Date();

    const dd = date.getDate().toString().padStart(2, '0');
    const MM = (date.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = date.getFullYear();

    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    const ss = date.getSeconds().toString().padStart(2, '0');

    return `${dd}${MM}${yyyy}${hh}${mm}${ss}`;
};
