import { TopBar } from 'components/common/TopBar';
import { Formik } from 'formik';
import { useSteps } from 'hooks';
import { passwordForgottenInitialProps } from 'pages/Sign/formikInitialProps';
import { MainWrapper, StepPageWrapper } from 'pages/Sign/styles';
import React from 'react';
import { PasswordForgottenStepName, PasswordForgottenStepsObject, SignStepProps } from 'types/sign';
import { EmailOrPhoneStep } from './Steps/EmailOrPhone';
// import { EmailStep } from './Steps/Email';
// import { PhoneStep } from './Steps/Phone';
import { NewPasswordStep } from './Steps/NewPassword';
import { SecurityCodeStep } from './Steps/SecurityCode';
import { SmsCodeStep } from './Steps/SmsCode';

const stepsObj: PasswordForgottenStepsObject = {
    // EmailStep,
    // PhoneStep,
    EmailOrPhoneStep,
    SmsCodeStep,
    SecurityCodeStep,
    NewPasswordStep
};

export const PasswordForgottenPage = () => {
    const [CurrentComponent, setCurrentStep, goToPrevStep] = useSteps<PasswordForgottenStepName, SignStepProps>(
        stepsObj
    );

    return (
        <Formik {...passwordForgottenInitialProps}>
            <StepPageWrapper>
                {/* <LoaderModal /> */}
                <TopBar onButtonClick={goToPrevStep} />
                <MainWrapper>
                    <CurrentComponent setCurrentStep={setCurrentStep} />
                </MainWrapper>
            </StepPageWrapper>
        </Formik>
    );
};
