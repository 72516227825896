import preloaderSvg from 'assets/preloader.svg';
import styled from 'styled-components';

// * need for classes and psevdo-classes
export const StyledInputWrapper = styled.div``;

export const Label = styled.label`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
`;

export const BottomHelpersWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    margin: 7px 0 10px;
    color: #aeadad;
    font-weight: 400;
`;

export const TopHelper = styled.div`
    font-size: 14px;
    margin: 7px 0 10px;
`;

export const BottomHelper = styled.div`
    cursor: pointer;
`;

export const PreloaderWrapper = styled.div`
    position: relative;
`;

export const Preloader = styled.div`
    @keyframes show {
        0% {
            opacity: 0;
        }
        99% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    position: absolute;
    right: 0;
    bottom: 3px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    background: #fff;
    animation: show 0.1s;

    &::before {
        content: url(${preloaderSvg});
        width: 20px;
        height: 20px;
    }
`;
