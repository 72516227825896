export const inputTextHorizontalPadding = '8px';
export const inputTextVerticalPadding = '10px';
export const inputLineHeight = '14px';
// export const inputBorderHeight = '3px';
export const inputHeight = parseInt(inputLineHeight) + 2 * parseInt(inputTextHorizontalPadding) + 'px';

export const inputInformationSpanHeight = '12px';

export const showPasswordButtonHeight = '18px';

export const loaderDiameter = '14px';
export const errorIconDiameter = '12px';
export const validIconDiameter = '12px';
//
// const calculateTopInputInformationIcon = (iconDiameter: string) =>
//     parseInt(inputHeight) / 2 - parseInt(iconDiameter) / 2 + 'px';
//
// export const loaderTop = calculateTopInputInformationIcon(loaderDiameter);
// export const errorIconTop = calculateTopInputInformationIcon(errorIconDiameter);
// export const validIconTop = calculateTopInputInformationIcon(errorIconDiameter);
// export const passwordButtonTop = calculateTopInputInformationIcon(showPasswordButtonHeight);
