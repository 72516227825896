import { white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';
import { BackgroundColor, BorderRadius, MarginRightBottom, MinSizes, Padding, Sizes } from 'types/styles';

export interface ContentWrapperProps
    extends Sizes,
        Padding,
        MinSizes,
        BackgroundColor,
        BorderRadius,
        MarginRightBottom {}

export const ContentWrapper = styled.div<ContentWrapperProps>`
    ${flexCenter}
    ${({ height }) => height && `height: ${height}`};
    ${({ width }) => width && `width: ${width}`};
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
    ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
    ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
    ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
    ${({ padding }) => padding && `padding: ${padding}`};
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
    background-color: ${({ backgroundColor }) => backgroundColor || white};
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
`;
