import styled from 'styled-components';

export const HiddenInput = styled.input`
    width: 1px;
    height: 1px;
    outline: none;
    border: 0;
    color: transparent;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    left: 0px;
    top: 20px;
    white-space: nowrap;
`;
