import { clearNode, Effect, guard } from 'effector';
import { FC, useCallback, useEffect, useState } from 'react';
import { stepsEvents } from 'stores/steps';

/**
 * Hook to get toggle
 */
export const useToggle = (): [boolean, () => void] => {
    const [visible, setVisible] = useState(false);

    const handleToggle = useCallback(() => setVisible(vis => !vis), []);

    return [visible, handleToggle];
};

/**
 * Hook to create steps and history
 */
export const useSteps = <N extends string, P>(
    stepsObj: { [name in N]: FC<P> }
): [FC<P>, (stepName: N) => void, () => void, N] => {
    // First object key will be first step
    const firstStep = Object.keys(stepsObj)[0] as N;

    const [currentStep, setCurrentStep] = useState(firstStep);
    const [stepsHistory, setStepsHistory] = useState([firstStep]);

    const CurrentComponent = stepsObj[currentStep];

    const setStep = (stepName: N) => {
        setCurrentStep(stepName);

        setStepsHistory(prev => [...prev, stepName]);
    };
    const goToPrevStep = () => {
        if (stepsHistory.length > 1) {
            setCurrentStep(stepsHistory[stepsHistory.length - 2]);
            setStepsHistory(prev => prev.slice(0, -1));
        } else {
            stepsEvents.goToPrevStep();
        }
    };

    return [CurrentComponent, setStep, goToPrevStep, currentStep];
};

/**
 * Debounce function
 */
export const useDebounce = (ms: number) => {
    const [memoObject] = useState({
        timerId: -1
    });

    return useCallback(
        (fn: Function) => {
            clearTimeout(memoObject.timerId);

            memoObject.timerId = setTimeout(fn, ms);
        },
        [memoObject, ms]
    );
};

/**
 * Check effect's pending status
 */
export const useEffectsPendingStatus = <Params, Done>(effect: Effect<Params, Done>): boolean => {
    // This hook prevents state update after component's unmount
    // caused by async effects.
    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        let isUnmounted = false;

        const event = guard({
            source: effect.pending,
            filter: () => !isUnmounted
        });
        event.watch(setIsPending);

        return () => {
            isUnmounted = true;
            clearNode(event); // Don't know if it's necessary. Added just in case
        };
    }, [effect.pending]);

    return isPending;
};
