import { StyledNavigationButton, StyledNavigationButtonProps } from 'components/common/buttons/NavigationButton/styles';
import React, { FC } from 'react';

interface Props extends Omit<StyledNavigationButtonProps, 'circle'> {
    onClick?: () => void;
    className?: string;
    type?: 'back' | 'submit' | 'button';
}

export const NavigationButton: FC<Props> = ({ type = 'button', ...rest }) => (
    <StyledNavigationButton button={type !== 'back'} type={type === 'submit' ? 'submit' : 'button'} {...rest} />
);
