import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { PasswordField } from 'components/Formik/PasswordField';
import { Column, Section } from 'components/wrappers/FlexWrapper';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import React, { FC, FormEvent } from 'react';
import { signEffects } from 'stores/sign';
import { Steps, stepsEvents } from 'stores/steps';
import { userEvents } from 'stores/user';
import { SignStepProps } from 'types/sign';

const { setNewPasswordViaEmail, setNewPasswordViaSms } = signEffects;

const { setToken, setUser } = userEvents;
const { setStep } = stepsEvents;

export const NewPasswordStep: FC<SignStepProps> = () => {
    const isLoading = useStore(setNewPasswordViaEmail.pending);
    const [, { value: email }] = useField('email');
    const [, { error: passwordError, value: password }] = useField('password');
    const [, { value: confirmationToken }] = useField('securityCode');
    const [, { value: smsCode }] = useField('smsCode');
    const [, { value: phoneNumber }] = useField('phone');
    const [, { value: countryCode }] = useField('countryCode');

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (passwordError) {
            return;
        }

        if (email && password && confirmationToken) {
            const response = await setNewPasswordViaEmail({ email, password, confirmationToken });

            if (response && response.token && response.user) {
                setToken(response.token);
                setUser(response.user);

                if (response.user.isAccountVerified && response.user.isEmailValidated) {
                    setStep(Steps.Login);
                } else {
                    setStep(Steps.Confirmation);
                }
            }
        }

        if (countryCode && phoneNumber && smsCode && password) {
            await setNewPasswordViaSms({
                newPassword: password,
                validationCode: smsCode,
                mobileNumber: countryCode + phoneNumber
            });

            setStep(Steps.Login);
        }
    };

    // useEffect(() => {
    //     isLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isLoading]);

    return (
        <Form onSubmit={onSubmit}>
            <Column alignCenter>
                <TitleBlock subtitle="Please enter your new password." title="Set New Password" />

                <Section justifyCenter marginBottom="25px" marginTop="52px">
                    <PasswordField />
                </Section>
                <Button disabled={!!passwordError} isLoading={isLoading}>
                    Next
                </Button>
            </Column>
        </Form>
    );
};
