import React, { FC } from 'react';
import { Visibility } from 'types/styles';
import { StyledModalTransparentWrapper, StyledModalWrapper } from './styles';

export interface ModalWrapperProps extends Visibility {}

export const ModalWrapper: FC<ModalWrapperProps> = ({ children, visible }) => {
    if (!visible) return null;

    return <StyledModalWrapper>{children}</StyledModalWrapper>;
};

export const ModalTransparentWrapper: FC<ModalWrapperProps> = ({ children, visible }) => {
    if (!visible) return null;

    return <StyledModalTransparentWrapper>{children}</StyledModalTransparentWrapper>;
};
