import questionIcon from 'assets/icons/question_icon.svg';
import { TitleBlock } from 'components/common/blocks/TitleBlock';
import { Button } from 'components/common/buttons/Button';
import { CustomImg } from 'components/common/CustomImg';
import { InputSelector } from 'components/common/grid/InputSelector';
import { inputTypes, LoginInputType } from 'components/common/grid/InputSelector/constants';
import { EmailField } from 'components/Formik/EmailField';
import { PhoneField } from 'components/Formik/PhoneField';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { Section } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { questionIconDiameter } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { Form, useField } from 'formik';
import React, { FC, FormEvent, useState } from 'react';
import { signEffects, signEvents } from 'stores/sign';
import { SignStepProps } from 'types/sign';

const { sendForgottenPasswordSmsCode, sendForgottenPasswordEmail, analyzeEmail, analyzeMobileNumber } = signEffects;
const { changeChosenSignInput } = signEvents;

export const EmailOrPhoneStep: FC<SignStepProps> = ({ setCurrentStep }) => {
    const [chosenInput, setChosenInput] = useState(inputTypes[0]);
    const isAnalizeEmailPending = useStore(analyzeEmail.pending);
    const isSmsSendPending = useStore(sendForgottenPasswordSmsCode.pending);
    const isAnalyzePhonePending = useStore(analyzeMobileNumber.pending);

    const isLoading = isAnalizeEmailPending || isSmsSendPending || isAnalyzePhonePending;

    /** toLowerCase because the first character of the form field is a lowercase letter. Example:
     * phone, email
     * But we use Phone, Email string (LoginInputType)
     * Phone -> Phone.toLowerCase() -> phone
     * Email -> Email.toLowerCase() -> email
     */

    const [, { error: chosenInputError, value: chosenInputValue }] = useField(chosenInput.toLowerCase());
    const [, { value: countryCode }] = useField('countryCode');

    const chooseEmail = () => {
        changeChosenSignInput('Email');
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (chosenInputError) {
            return;
        }

        if (chosenInput === 'Phone') {
            // If .then used instead of .finally,
            // 409 response throws error and setCurrentStep isn't fired
            sendForgottenPasswordSmsCode(countryCode + chosenInputValue).finally(() => {
                setCurrentStep('SmsCodeStep');
            });
        } else if (chosenInput === 'Email') {
            sendForgottenPasswordEmail(chosenInputValue);
            setCurrentStep('SecurityCodeStep');
        }
    };

    const onChangeLoginType = (item: LoginInputType) => {
        setChosenInput(item);
    };

    // useEffect(() => {
    //     isLoading ? loaderModal.openModal() : loaderModal.closeModal();
    // }, [isLoading]);

    return (
        <Form onSubmit={handleFormSubmit}>
            <AbsoluteWrapper right="25px" top="70px">
                <CustomImg height={questionIconDiameter} src={questionIcon} width={questionIconDiameter} />
            </AbsoluteWrapper>
            <MarginWrapper marginBottom="24px">
                <TitleBlock subtitle="Please enter your registered Phone Number or email." title="Forgot Password?" />
            </MarginWrapper>
            <InputSelector
                activeItem={chosenInput}
                inputs={[
                    {
                        type: 'Phone',
                        input: <PhoneField key="Phone" />,
                        title: 'Phone'
                    },
                    {
                        type: 'Email',
                        input: <EmailField key="Email" isLoading={isAnalizeEmailPending} onFocus={chooseEmail} />,
                        title: 'Email'
                    }
                ]}
                // items={inputTypes}
                onChange={onChangeLoginType}
            />
            {/*{(!chosenInput || chosenInput === 'phone') && (*/}
            {/*    <PhoneField withLeftHelper={!!chosenInput} onFocus={choosePhone} />*/}
            {/*)}*/}
            {/*{(!chosenInput || chosenInput === 'email') && (*/}
            {/*    <EmailField isLoading={isEmailLoading} withLeftHelper={!!chosenInput} onFocus={chooseEmail} />*/}
            {/*)}*/}
            {/*</InputSelector>*/}
            {/*<InputsWrapper>*/}
            {/*</InputsWrapper>*/}

            <Section justifyCenter marginBottom="32px" marginTop="40px">
                <Button disabled={!!chosenInputError} height="48px" isLoading={isLoading} type="submit">
                    {chosenInput === 'Email' ? 'Next' : 'Send Code'}
                </Button>
            </Section>
        </Form>
    );
};
