import axios from 'services/axios';
import { womBaseURL, yeayBaseURL } from 'constants/stores';

export const queryListOfProducts = (data: YEAY.GetProductsByNameRequest) =>
    axios<YEAY.GetProductsResponse>({
        url: '/product/query-by-name',
        baseURL: yeayBaseURL(),
        data
    });

export const createProduct = (data: WOM.CreateProductRequest) =>
    axios<WOM.ProductResponse>({
        url: '/product/create',
        baseURL: womBaseURL(),
        data
    });
